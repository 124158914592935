import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext } from 'react';
import { FirebaseAppContext } from 'libs.firebase_react';
import { useLocalizationImport } from 'libs.localization.react';
import { Button } from 'libs.nucleus.button';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Spinner } from 'libs.nucleus.spinner';
import { Tooltip } from 'libs.nucleus.tooltip';
import { useBooleanState } from 'libs.react.hooks';
import { ImportModal } from '../study/import_modal';
export const DashboardImportAction = ({ isDisabled, variant = 'secondary', label, requiredIds = [], supportedLocales = [], showTooltip = false, tooltipText = '', trackingContext = {}, onImport = () => { }, }) => {
    const t = useLocalizeMessage();
    const [isImportModalOpen, openImportModal, closeImportModal] = useBooleanState(false);
    const { isPending: isImporting, importLocalization } = useLocalizationImport();
    const { logEvent } = useContext(FirebaseAppContext);
    const handleImport = useCallback(async ({ files }) => {
        const formData = new FormData();
        for (const file in files) {
            formData.append(file, files[file]);
        }
        importLocalization({ formData, requiredIds, supportedLocales }, {
            onSuccess: () => {
                closeImportModal();
            },
            onSettled: onImport,
        });
        logEvent('translations_localization_imported', {
            ...trackingContext,
            requiredIds: requiredIds.join(','),
            supportedLocales: supportedLocales.join(','),
        });
    }, []);
    return (_jsxs(_Fragment, { children: [showTooltip ? (_jsx(Tooltip, { title: tooltipText, placement: 'top', children: _jsx(Button, { variant: variant, onClick: openImportModal, label: label || t('Import'), disabled: isDisabled, content: isImporting ? (_jsx("div", { className: 'py-1', children: _jsx(Spinner, { size: 'sm', variant: variant === 'primary' ? 'alt' : 'default' }) })) : undefined }) })) : (_jsx(Button, { variant: variant, onClick: openImportModal, label: label || t('Import'), disabled: isDisabled, content: isImporting ? (_jsx("div", { className: 'py-1', children: _jsx(Spinner, { size: 'sm', variant: variant === 'primary' ? 'alt' : 'default' }) })) : undefined })), isImportModalOpen && (_jsx(ImportModal, { onModalClose: closeImportModal, onHandleImport: handleImport, width: 'base', isLoading: isImporting }))] }));
};
