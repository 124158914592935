import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { isString } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { useBundlePreview } from 'libs.bundle_preview';
import { LocaleContext, useLocalizeMessage } from 'libs.nucleus.i18n';
import { Spinner } from 'libs.nucleus.spinner';
import { useToastNotification } from 'libs.react.hooks';
import { ActivityRunner } from './activity_runner';
import { ecoa_hooks } from './ecoa_hooks';
import { EIframeMessageTypes } from '../../../../constants/iframe_message.enum';
import { assignAnswerStatus, getStepEndDate, getStepStartDate } from '../../../../helpers/ecoa.format.helpers';
import { useCurrentEnvironment } from '../../../../hooks/use_current_environment';
import { useOrgContext } from '../../../../hooks/use_org_context';
import { useSiteContext } from '../../../../hooks/use_site_context';
import { activityManager } from '../../helper_models/activity_manager';
export const ActivityEcoaView = ({ visitActivity, setIsActivityModalClosed }) => {
    const activity = visitActivity.activity.c_group_task.c_assignment;
    const currentVisit = visitActivity.visit;
    const { environment } = useCurrentEnvironment();
    const org = useOrgContext();
    const { addNotification } = useToastNotification();
    const translate = useLocalizeMessage();
    const [basePreviewURL, setBasePreviewURL] = useState();
    const site = useSiteContext();
    const currentLocale = useContext(LocaleContext);
    const match = useMatch('/site/study/:studyId/participants/:participantId/*');
    const currentActivityResponse = activityManager.initActivityResponseData({
        activityId: activity._id,
        studyId: org._id,
        publicUserId: match?.params.participantId || '',
        currentGroupId: currentVisit.c_group,
        visitId: currentVisit.c_visit,
        siteId: site._id || '',
        currentLocale,
    });
    const fetchAWSBundle = async () => {
        if (!activity.c_html_bundle) {
            throw new Error('HTML bundle is undefined');
        }
        const response = await fetch(activity.c_html_bundle.url);
        const blobResponse = await response.blob();
        return blobResponse;
    };
    const { data: activityBlob, isLoading } = useQuery({
        queryKey: ['activityBundle', activity._id],
        queryFn: fetchAWSBundle,
        enabled: true,
    });
    const { bundlePreview: activityBundleView } = useBundlePreview(activityBlob);
    const handleIframeMessage = async ({ data }) => {
        const { type, message } = data;
        if (type === EIframeMessageTypes.CANCEL) {
            setIsActivityModalClosed();
            return;
        }
        if (type === EIframeMessageTypes.SUBMIT) {
            const { responses, events } = message;
            const { c_steps: { data: steps }, } = activity;
            responses.forEach(({ stepId, value, original_value, status }) => {
                const c_step = steps.find(({ c_ecoa_step_id }) => c_ecoa_step_id === stepId);
                if (!c_step) {
                    return;
                }
                const c_start_date = getStepStartDate(stepId, events);
                const c_end_date = getStepEndDate(stepId, events);
                let c_value = value;
                if (c_step.c_type === 'text_choice' && isString(value)) {
                    c_value = [value];
                }
                const response = {
                    c_step,
                    c_value,
                    c_start_date,
                    c_end_date,
                    c_original_value: original_value,
                    ...assignAnswerStatus(status),
                };
                currentActivityResponse.updateStepResponse(response);
            });
            try {
                await currentActivityResponse.upload({ environment });
                addNotification({
                    title: '',
                    subtitle: translate('Activity response uploaded successfully'),
                    type: 'success',
                });
                setIsActivityModalClosed();
            }
            catch (error) {
                addNotification({
                    title: '',
                    subtitle: translate('Error uploading activity response'),
                    type: 'error',
                });
            }
        }
    };
    useEffect(() => {
        if (activityBundleView) {
            activityBundleView.setScript(ecoa_hooks);
            const url = activityBundleView.getScreenURL(''); // gets the first screen on the activity
            setBasePreviewURL(url);
        }
        window.addEventListener('message', handleIframeMessage);
        return () => {
            window.removeEventListener('message', handleIframeMessage);
        };
    }, [activityBundleView]);
    if (isLoading || !basePreviewURL) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    return _jsx(ActivityRunner, { basePreviewURL: basePreviewURL });
};
