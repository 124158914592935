import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { SideNav } from 'libs.nucleus.side_nav';
import { Spinner } from 'libs.nucleus.spinner';
import { AxonAccountContext } from '../../contexts/axon_account';
import { OrgContext } from '../../contexts/org';
import { SiteContext } from '../../contexts/site';
import { useCurrentEnvironment } from '../../hooks/use_current_environment/use_current_environment.hook';
import { useNavigation } from '../../hooks/use_navigation';
export const SitesWrapper = () => {
    const { pathname } = useLocation();
    const { environment } = useCurrentEnvironment();
    const { account } = useContext(AxonAccountContext);
    const { site } = useContext(SiteContext);
    const { org } = useContext(OrgContext);
    const studyCardProps = useMemo(() => {
        return { protocolNumber: environment?.studyId?.toString() || '', studyName: environment?.name || '' };
    }, [environment?.name, environment?.studyId]);
    const { navItems } = useNavigation();
    return (_jsxs("div", { className: 'flex grow overflow-hidden', children: [_jsx("div", { className: 'flex flex-col bg-neutral-75', "data-testid": 'side-nav-menu', children: _jsx(SideNav, { activeLinkPath: pathname, items: navItems, LinkComponent: Link, studyCard: studyCardProps }) }), _jsx("div", { className: 'flex h-full w-full grow flex-col overflow-hidden bg-white', children: !environment || !account || !site || !org ? _jsx(Spinner, { wrapper: 'full' }) : _jsx(Outlet, {}) })] }));
};
