export var EStepTypes;
(function (EStepTypes) {
    EStepTypes["BOOLEAN"] = "boolean";
    EStepTypes["COMPLETION"] = "completion";
    EStepTypes["DATE"] = "date";
    EStepTypes["DATETIME"] = "datetime";
    EStepTypes["NUMERIC"] = "numeric";
    EStepTypes["TEXT"] = "text";
    EStepTypes["TEXT_CHOICE"] = "text_choice";
    EStepTypes["FORM"] = "form";
    EStepTypes["REVIEW"] = "review";
    EStepTypes["PARTICIPANT_ID"] = "participant_id";
})(EStepTypes || (EStepTypes = {}));
