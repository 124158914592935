import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from 'libs.nucleus.button';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
export const ActivityStandardView = ({ visitActivity, setIsActivityModalClosed }) => {
    const activity = visitActivity.activity.c_group_task.c_assignment;
    const translate = useLocalizeMessage();
    return (_jsxs(_Fragment, { children: [_jsxs("p", { children: [activity.c_name, ", standard view to be done"] }), _jsx(Button, { label: translate('Cancel'), onClick: () => {
                    setIsActivityModalClosed();
                } })] }));
};
