import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useCallback } from 'react';
import { FirebaseAppContext } from 'libs.firebase_react';
import { Button } from 'libs.nucleus.button';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { ListInformation } from 'libs.react.components';
import { useBooleanState, useDateTime } from 'libs.react.hooks';
import { TranslationStartedNotification } from 'libs.translation_workbench';
import { StudyDetailsEdit } from './study_details_edit.component';
import { StudiesContext, StudyConfigurationContext } from '../../../contexts';
export const StudyDetails = () => {
    const translate = useLocalizeMessage();
    const { parseDateShort } = useDateTime();
    const { assignStudy, currentStudy, isReadOnlyMode, studyId } = useContext(StudyConfigurationContext);
    const { updateStudyMetadata } = useContext(StudiesContext);
    const [isEditModalOpen, openEditModal, closeEditModal] = useBooleanState(false);
    const { logEvent } = useContext(FirebaseAppContext);
    const handleSaveEdit = useCallback(({ name, description = '', displayName = '', protocolNumber = '', protocolVersion = '', protocolVersionDate = '', metadata, }) => {
        currentStudy.name = name;
        currentStudy.displayName = displayName;
        currentStudy.description = description;
        currentStudy.protocolNumber = protocolNumber;
        currentStudy.protocolVersion = protocolVersion;
        currentStudy.protocolVersionDate = protocolVersionDate;
        currentStudy.updateStudyMetadata(metadata);
        assignStudy(currentStudy);
        updateStudyMetadata(studyId, { name, protocolNumber, protocolVersion, protocolVersionDate, description });
        logEvent('study_details_updated', { study_id: studyId });
        closeEditModal();
    }, [currentStudy]);
    const detailItems = [
        { title: translate('Study name'), description: currentStudy.name || '' },
        { title: translate('Sponsor name'), description: currentStudy.sponsor || '' },
        { title: translate('Display name'), description: currentStudy.displayName || '' },
        { title: translate('Protocol number'), description: currentStudy.protocolNumber || '' },
        { title: translate('Protocol version'), description: currentStudy.protocolVersion || '' },
        {
            title: translate('Protocol date'),
            description: parseDateShort(currentStudy.protocolVersionDate || '', { ignoreTimeZone: true }),
        },
        { title: translate('Description'), description: currentStudy.description || '' },
    ];
    return (_jsxs("div", { className: 'flex flex-col gap-8', children: [isReadOnlyMode && _jsx(TranslationStartedNotification, {}), _jsx("div", { className: 'ml-auto', children: _jsx(Button, { label: translate('Edit details'), onClick: openEditModal, disabled: isReadOnlyMode }) }), _jsx(ListInformation, { dataTestId: 'study-details-list-information', items: detailItems, useDefaultEmptyDescription: true }), isEditModalOpen && (_jsx(StudyDetailsEdit, { onCancel: closeEditModal, onSave: handleSaveEdit, initialStudy: currentStudy }))] }));
};
