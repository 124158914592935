export const stepResponseTypes = {
    cBoolean: {
        objectType: 'c_boolean',
    },
    cNumeric: {
        objectType: 'c_numeric',
    },
    cText: {
        objectType: 'c_text',
    },
    cTextChoice: {
        objectType: 'c_text_choice',
    },
    cReview: {
        objectType: 'c_review',
    },
    cForm: {
        objectType: 'c_form',
    },
    cParticipantId: {
        objectType: 'c_participant_id',
    },
    cDate: {
        objectType: 'c_date',
    },
    cDatetime: {
        objectType: 'c_datetime',
    },
    cCompletion: {
        objectType: 'c_completion',
    },
};
