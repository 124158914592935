import { useMemo } from 'react';
import { LocalizationStatus, } from 'libs.localization.common';
import { useLocalizationCollection } from 'libs.localization.react';
import { assetConditions } from '../utils/asset_conditions';
import { getVersionStatus, mapStudyAssetToRole } from '../utils/data_utils';
export const useStudyStringsData = (study, participantLocales = [], siteLocales = []) => {
    const assetKeys = Object.keys(assetConditions);
    const locales = Array.from(new Set([...participantLocales, ...siteLocales]));
    const assets = assetKeys.reduce((acc, assetKey) => {
        const { condition, getAsset = () => { } } = assetConditions[assetKey] || {};
        if (condition && condition(study)) {
            return [...acc, getAsset(study)];
        }
        return acc;
    }, []);
    const { localizationsMap, isLoading } = useLocalizationCollection(assets);
    const _data = useMemo(() => {
        if (!assetKeys || !locales.length || !study || !Object.keys(study).length) {
            return {};
        }
        const data = {};
        assetKeys.forEach((assetKey) => {
            const { condition, displayName, type, getAsset = () => { } } = assetConditions[assetKey] || {};
            const localeData = {};
            for (const locale of locales) {
                localeData[locale] = locale === 'en-US' ? LocalizationStatus.READY : LocalizationStatus.NOT_STARTED;
            }
            const _asset = assets.find((a) => a.type === assetKey);
            const { localizationEntry, localization, } = localizationsMap?.get(_asset) || {};
            const translationWorkbenchId = localization?.getId();
            const role = mapStudyAssetToRole(assetKey);
            const versionStatus = getVersionStatus({
                localizationEntry,
                participantLocales,
                siteLocales,
                role,
                translationWorkbenchId,
                assetType: assetKey,
            });
            if (condition && condition(study)) {
                const asset = getAsset(study);
                data[displayName] = {
                    translationWorkbenchId,
                    type,
                    statuses: localeData,
                    path: asset.id,
                    assetType: assetKey,
                    id: assetKey,
                    asset,
                    role,
                    ...versionStatus,
                };
            }
        });
        return data;
    }, [assets, localizationsMap]);
    return { data: _data, isLoading };
};
