import { useContext, useState } from 'react';
import { FirebaseAppContext } from 'libs.firebase_react';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { useDialog } from 'libs.nucleus.modal_dialog';
import { useApiClient } from 'libs.react.contexts';
import { useBooleanState, useToastNotification } from 'libs.react.hooks';
import { StudyConfigurationContext } from '../../contexts';
export const useResourceHandler = (isViewMode = false) => {
    const translate = useLocalizeMessage();
    const dialog = useDialog();
    const { delete: apiDelete, get } = useApiClient("library" /* ApiClientService.LIBRARY */);
    const toast = useToastNotification();
    const [content, setContent] = useState({ id: '', name: '', content: '' });
    const [resourceToEdit, setResourceToEdit] = useState(undefined);
    const [isCreateResourceModalOpen, openCreateResourceModal, closeCreateResourceModal] = useBooleanState(false);
    const { currentStudy, assignStudy, studyId } = useContext(StudyConfigurationContext);
    const { logEvent } = useContext(FirebaseAppContext);
    const handleOnSave = (resource, updatedContent) => {
        const studyResources = [...currentStudy.studyResources];
        setContent(updatedContent);
        const resourceIdx = studyResources.findIndex((r) => r.id === resource.id);
        if (resourceIdx > -1) {
            studyResources[resourceIdx] = resource;
        }
        else {
            studyResources.push(resource);
        }
        currentStudy.studyResources = studyResources;
        assignStudy(currentStudy);
        logEvent(resourceIdx > -1 ? 'study_resource_edited' : 'study_resource_added', {
            study_id: currentStudy.id,
            resource_name: resource.title,
        });
        handleCloseModal();
    };
    const handleEditResource = async (resource, loadTextContent) => {
        if (loadTextContent && resource.contentId) {
            const response = await get(`/v1/asset/${resource.contentId}`);
            const libContent = response.data.data;
            setContent({ id: libContent.id, name: libContent.name, content: libContent.data.content });
        }
        setResourceToEdit(resource);
        openCreateResourceModal();
    };
    const handleCloseModal = () => {
        closeCreateResourceModal();
        setResourceToEdit(undefined);
        // We need to keep the content in view mode as it is used to be displayed to users
        if (!isViewMode) {
            setContent(undefined);
        }
    };
    const handleDeleteResource = async (resource) => {
        const ok = await dialog.open({
            title: translate('Delete resource'),
            body: translate('Are you sure you want to delete this resource? This action cannot be undone.'),
            actionButtons: [{ isDestructive: true, label: translate('Delete') }, { label: translate('Cancel') }],
        });
        if (ok) {
            try {
                if (resource.files?.length) {
                    const filesToDelete = resource.files.map(({ id }) => apiDelete(`/v1/asset/${id}`));
                    await Promise.all(filesToDelete);
                }
                else if (resource.contentId) {
                    await apiDelete(`/v1/asset/${resource.contentId}`);
                }
            }
            catch (error) {
                if (error.response?.status !== 404) {
                    console.error('Error deleting files', error);
                    toast.addNotification({
                        type: 'error',
                        title: translate('Something went wrong while attempting to delete files linked to this resource. Please try again.'),
                    });
                    return;
                }
            }
            currentStudy.studyResources = currentStudy.studyResources.filter(({ id }) => id !== resource.id);
            logEvent('study_resource_removed', { study_id: studyId, resource_name: resource.title });
            assignStudy(currentStudy);
            setResourceToEdit(undefined);
        }
    };
    return {
        resourceToEdit,
        isCreateResourceModalOpen,
        openCreateResourceModal,
        handleOnSave,
        handleEditResource,
        handleDeleteResource,
        handleCloseModal,
        content,
        setContent,
    };
};
