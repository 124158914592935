export const workspace_sites_mock_data = [
    {
        id: 'FO1950-IP',
        entityId: 'EID-FO1950-IP',
        name: 'Medical Investigation Z',
        number: 'MSID5678',
        timezone: 'America/Toronto',
        therapeuticArea: 'cardiology',
        email: 'bclelle0@state.tx.us',
        siteLocales: ['en_US', 'es_ES'],
        participantsLocales: ['de_DE'],
        address: {
            countryCode: 'CA',
            addressLine: '9 Dexter Hill',
            city: 'Espanola',
            region: 'Georgia',
            postalCode: 'T9L 4V1',
            phone: [{ type: 'daytime', number: '718-343-8583' }],
        },
        userCount: 42,
        studyCount: 63,
    },
    {
        id: 'YZ2080-FS',
        entityId: 'EID-YZ2080-FS',
        name: 'Medical Investigation Z',
        number: 'MSID2345',
        timezone: 'Asia/Shanghai',
        therapeuticArea: 'gastroenterology',
        email: 'cbruckman1@mlb.com',
        siteLocales: ['de_DE'],
        participantsLocales: ['it_IT'],
        address: {
            countryCode: 'CN',
            addressLine: '3 Kingsford Street',
            city: 'Yangzizhou',
            region: 'Utah',
            postalCode: 'N4H 8K3',
            phone: [{ type: 'daytime', number: '605-735-0206' }],
        },
        userCount: 18,
        studyCount: 9,
    },
    {
        id: 'YT4352-EB',
        entityId: 'EID-YT4352-EB',
        name: 'Clinical Trial Y',
        number: 'MSID9012',
        timezone: 'Europe/Moscow',
        therapeuticArea: 'endocrinology',
        email: 'vwickenden2@mozilla.com',
        siteLocales: ['fr_FR', 'de_DE'],
        participantsLocales: ['en_US', 'de_DE', 'es_ES'],
        address: {
            countryCode: 'RU',
            addressLine: '67 Derek Road',
            city: 'Zelenograd',
            region: 'Rhode Island',
            postalCode: 'S6T 1N2',
            phone: [{ type: 'daytime', number: '222-462-4727' }],
        },
        userCount: 38,
        studyCount: 43,
    },
    {
        id: 'OL1919-UV',
        entityId: 'EID-OL1919-UV',
        name: 'Clinical Trial Y',
        number: 'MSID7890',
        timezone: 'Asia/Ulaanbaatar',
        therapeuticArea: 'urology',
        email: 'tpigeram3@meetup.com',
        siteLocales: ['de_DE', 'fr_FR', 'en_US'],
        participantsLocales: ['en_US'],
        address: {
            countryCode: 'MN',
            addressLine: '52414 Utah Trail',
            city: 'Telmin Suma',
            region: 'South Dakota',
            postalCode: 'L1J 3G2',
            phone: [{ type: 'daytime', number: '328-193-0221' }],
        },
        userCount: 7,
        studyCount: 38,
    },
    {
        id: 'DQ8119-EU',
        entityId: 'EID-DQ8119-EU',
        name: 'Study A',
        number: 'MSID0123',
        timezone: 'Asia/Ulaanbaatar',
        therapeuticArea: 'gastroenterology',
        email: 'wfeild4@foxnews.com',
        siteLocales: ['it_IT'],
        participantsLocales: ['de_DE', 'fr_FR'],
        address: {
            countryCode: 'MN',
            addressLine: '2 Artisan Road',
            city: 'Telmin Suma',
            region: 'Kentucky',
            postalCode: 'Z9Z 4S8',
            phone: [{ type: '24-hour', number: '738-272-9081' }],
        },
        userCount: 2,
        studyCount: 45,
    },
    {
        id: 'LW4514-JZ',
        entityId: 'EID-LW4514-JZ',
        name: 'Clinical Trial Y',
        number: 'MSID8901',
        timezone: 'Europe/Oslo',
        therapeuticArea: 'neurology',
        email: 'tphilipsen5@blog.com',
        siteLocales: ['en_US', 'fr_FR', 'de_DE'],
        participantsLocales: ['fr_FR', 'it_IT', 'en_US'],
        address: {
            countryCode: 'NO',
            addressLine: '1688 Gateway Street',
            city: 'Oslo',
            region: 'West Virginia',
            postalCode: 'U9U 7W9',
            phone: [{ type: 'daytime', number: '163-971-8090' }],
        },
        userCount: 55,
        studyCount: 11,
    },
    {
        id: 'DF5288-SW',
        entityId: 'EID-DF5288-SW',
        name: 'Research Project X',
        number: 'MSID3456',
        timezone: 'Europe/Moscow',
        therapeuticArea: 'urology',
        email: 'enadin6@ftc.gov',
        siteLocales: ['es_ES', 'de_DE', 'en_US'],
        participantsLocales: ['it_IT', 'es_ES', 'fr_FR'],
        address: {
            countryCode: 'RU',
            addressLine: '61342 Dunning Trail',
            city: 'Podgornaya',
            region: 'Maryland',
            postalCode: 'R9B 4I3',
            phone: [{ type: '24-hour', number: '969-140-2116' }],
        },
        userCount: 23,
        studyCount: 34,
    },
    {
        id: 'RU3865-WQ',
        entityId: 'EID-RU3865-WQ',
        name: 'Clinical Trial Y',
        number: 'MSID6789',
        timezone: 'Asia/Manila',
        therapeuticArea: 'ophthalmology',
        email: 'blivingstone7@cam.ac.uk',
        siteLocales: ['de_DE'],
        participantsLocales: ['fr_FR', 'it_IT'],
        address: {
            countryCode: 'PH',
            addressLine: '6816 Judy Circle',
            city: 'Lila',
            region: 'Alabama',
            postalCode: 'K1S 3D6',
            phone: [{ type: 'daytime', number: '536-534-0437' }],
        },
        userCount: 0,
        studyCount: 10,
    },
    {
        id: 'PB8785-FP',
        entityId: 'EID-PB8785-FP',
        name: 'Clinical Trial Y',
        number: 'MSID1234',
        timezone: 'Europe/Moscow',
        therapeuticArea: 'endocrinology',
        email: 'gsewill8@nymag.com',
        siteLocales: ['de_DE'],
        participantsLocales: ['it_IT', 'es_ES', 'de_DE'],
        address: {
            countryCode: 'RU',
            addressLine: '823 Graceland Pass',
            city: 'Chaltyr',
            region: 'Oregon',
            postalCode: 'A1T 9I6',
            phone: [{ type: '24-hour', number: '350-935-1365' }],
        },
        userCount: 100,
        studyCount: 42,
    },
    {
        id: 'UW5172-WM',
        entityId: 'EID-UW5172-WM',
        name: 'Research Project X',
        number: 'MSID3456',
        timezone: 'Africa/Dar_es_Salaam',
        therapeuticArea: 'cardiology',
        email: 'uwreak9@archive.org',
        siteLocales: ['es_ES'],
        participantsLocales: ['en_US'],
        address: {
            countryCode: 'TZ',
            addressLine: '37207 Arizona Circle',
            city: 'Mikumi',
            region: 'North Dakota',
            postalCode: 'P9D 9W4',
            phone: [{ type: 'daytime', number: '131-463-7786' }],
        },
        userCount: 84,
        studyCount: 49,
    },
    {
        id: 'MF7926-PV',
        entityId: 'EID-MF7926-PV',
        name: 'Medical Investigation Z',
        number: 'MSID6789',
        timezone: 'Europe/Podgorica',
        therapeuticArea: 'neurology',
        email: 'egillyetta@tuttocitta.it',
        siteLocales: ['it_IT', 'es_ES', 'en_US'],
        participantsLocales: ['es_ES', 'fr_FR', 'de_DE'],
        address: {
            countryCode: 'ME',
            addressLine: '3 Sundown Road',
            city: 'Budva',
            region: 'New Jersey',
            postalCode: 'M9J 7P4',
            phone: [{ type: '24-hour', number: '515-340-8670' }],
        },
        userCount: 100,
        studyCount: 7,
    },
    {
        id: 'UY9210-BP',
        entityId: 'EID-UY9210-BP',
        name: 'Study A',
        number: 'MSID7890',
        timezone: 'America/Monterrey',
        therapeuticArea: 'oncology',
        email: 'jletessierb@time.com',
        siteLocales: ['fr_FR', 'es_ES'],
        participantsLocales: ['es_ES'],
        address: {
            countryCode: 'MX',
            addressLine: '7636 Forest Dale Trail',
            city: 'Francisco Villa',
            region: 'California',
            postalCode: 'V0E 7C2',
            phone: [{ type: 'daytime', number: '353-300-1108' }],
        },
        userCount: 31,
        studyCount: 49,
    },
    {
        id: 'GC0638-HA',
        entityId: 'EID-GC0638-HA',
        name: 'Medical Investigation Z',
        number: 'MSID6789',
        timezone: 'Asia/Shanghai',
        therapeuticArea: 'pulmonology',
        email: 'mstivanic@usnews.com',
        siteLocales: ['es_ES', 'it_IT'],
        participantsLocales: ['en_US', 'es_ES'],
        address: {
            countryCode: 'CN',
            addressLine: '3952 Bobwhite Point',
            city: 'Changleng',
            region: 'Missouri',
            postalCode: 'X6E 5A8',
            phone: [{ type: '24-hour', number: '368-313-5277' }],
        },
        userCount: 9,
        studyCount: 82,
    },
    {
        id: 'UD1905-LW',
        entityId: 'EID-UD1905-LW',
        name: 'Medical Investigation Z',
        number: 'MSID7890',
        timezone: 'Europe/Paris',
        therapeuticArea: 'oncology',
        email: 'bmabbed@gravatar.com',
        siteLocales: ['en_US', 'es_ES'],
        participantsLocales: ['de_DE', 'es_ES'],
        address: {
            countryCode: 'FR',
            addressLine: '14 Bultman Park',
            city: 'Romorantin-Lanthenay',
            region: 'West Virginia',
            postalCode: 'V1T 1H3',
            phone: [{ type: 'daytime', number: '248-963-0280' }],
        },
        userCount: 16,
        studyCount: 32,
    },
    {
        id: 'FA1383-CM',
        entityId: 'EID-FA1383-CM',
        name: 'Medical Investigation Z',
        number: 'MSID7890',
        timezone: 'America/Panama',
        therapeuticArea: 'ophthalmology',
        email: 'ldumigane@theatlantic.com',
        siteLocales: ['en_US', 'it_IT'],
        participantsLocales: ['fr_FR'],
        address: {
            countryCode: 'PA',
            addressLine: '54 Golden Leaf Street',
            city: 'Panamá',
            region: 'Utah',
            postalCode: 'W3L 8A5',
            phone: [{ type: 'daytime', number: '779-640-8213' }],
        },
        userCount: 0,
        studyCount: 52,
    },
    {
        id: 'GL5168-DN',
        entityId: 'EID-GL5168-DN',
        name: 'Study A',
        number: 'MSID6789',
        timezone: 'Europe/Warsaw',
        therapeuticArea: 'rheumatology',
        email: 'utraynorf@washingtonpost.com',
        siteLocales: ['it_IT', 'es_ES', 'en_US'],
        participantsLocales: ['en_US', 'de_DE'],
        address: {
            countryCode: 'PL',
            addressLine: '73 Shelley Drive',
            city: 'Chrząstowice',
            region: 'Kentucky',
            postalCode: 'H9J 8R0',
            phone: [{ type: '24-hour', number: '146-896-1162' }],
        },
        userCount: 69,
        studyCount: 1,
    },
    {
        id: 'TZ6099-DF',
        entityId: 'EID-TZ6099-DF',
        name: 'Medical Investigation Z',
        number: 'MSID5678',
        timezone: 'Asia/Kuching',
        therapeuticArea: 'urology',
        email: 'weavesg@homestead.com',
        siteLocales: ['fr_FR'],
        participantsLocales: ['de_DE'],
        address: {
            countryCode: 'MY',
            addressLine: '4 Vidon Way',
            city: 'Kota Kinabalu',
            region: 'Virginia',
            postalCode: 'B6H 7I0',
            phone: [{ type: '24-hour', number: '918-347-1490' }],
        },
        userCount: 4,
        studyCount: 77,
    },
    {
        id: 'JN8873-ED',
        entityId: 'EID-JN8873-ED',
        name: 'Study A',
        number: 'MSID1234',
        timezone: 'Asia/Karachi',
        therapeuticArea: 'oncology',
        email: 'wkylesh@time.com',
        siteLocales: ['de_DE', 'fr_FR'],
        participantsLocales: ['es_ES', 'en_US', 'fr_FR'],
        address: {
            countryCode: 'PK',
            addressLine: '66 Farwell Crossing',
            city: 'Chowki Jamali',
            region: 'Texas',
            postalCode: 'Q2T 3M7',
            phone: [{ type: 'daytime', number: '267-633-6237' }],
        },
        userCount: 19,
        studyCount: 67,
    },
    {
        id: 'DB4548-HO',
        entityId: 'EID-DB4548-HO',
        name: 'Research Project X',
        number: 'MSID3456',
        timezone: 'Asia/Manila',
        therapeuticArea: 'gastroenterology',
        email: 'alodemanni@behance.net',
        siteLocales: ['es_ES', 'de_DE', 'it_IT'],
        participantsLocales: ['fr_FR', 'de_DE', 'en_US'],
        address: {
            countryCode: 'PH',
            addressLine: '774 La Follette Lane',
            city: 'Calumpit',
            region: 'Connecticut',
            postalCode: 'F5K 3I6',
            phone: [{ type: 'daytime', number: '780-788-5609' }],
        },
        userCount: 64,
        studyCount: 41,
    },
    {
        id: 'NF2304-BJ',
        entityId: 'EID-NF2304-BJ',
        name: 'Study A',
        number: 'MSID7890',
        timezone: 'Asia/Jakarta',
        therapeuticArea: 'endocrinology',
        email: 'eramsbyj@amazon.de',
        siteLocales: ['de_DE', 'fr_FR'],
        participantsLocales: ['fr_FR', 'en_US'],
        address: {
            countryCode: 'ID',
            addressLine: '68728 Wayridge Junction',
            city: 'Bodeh',
            region: 'Georgia',
            postalCode: 'L2N 9E3',
            phone: [{ type: '24-hour', number: '250-273-2943' }],
        },
        userCount: 98,
        studyCount: 35,
    },
    {
        id: 'XL9704-JX',
        entityId: 'EID-XL9704-JX',
        name: 'Research Project X',
        number: 'MSID1234',
        timezone: 'Asia/Jakarta',
        therapeuticArea: 'cardiology',
        email: 'abearfootk@cnbc.com',
        siteLocales: ['fr_FR', 'es_ES', 'en_US'],
        participantsLocales: ['de_DE', 'en_US'],
        address: {
            countryCode: 'ID',
            addressLine: '3 Eliot Crossing',
            city: 'Anyar',
            region: 'Georgia',
            postalCode: 'R3E 6J7',
            phone: [{ type: '24-hour', number: '328-647-4106' }],
        },
        userCount: 54,
        studyCount: 65,
    },
    {
        id: 'LM2667-YU',
        entityId: 'EID-LM2667-YU',
        name: 'Medical Investigation Z',
        number: 'MSID2345',
        timezone: 'Asia/Jakarta',
        therapeuticArea: 'gastroenterology',
        email: 'tdomengel@mapquest.com',
        siteLocales: ['es_ES', 'en_US'],
        participantsLocales: ['de_DE', 'en_US'],
        address: {
            countryCode: 'ID',
            addressLine: '5 Wayridge Court',
            city: 'Poponcol',
            region: 'Michigan',
            postalCode: 'O4J 8C0',
            phone: [{ type: '24-hour', number: '688-640-0252' }],
        },
        userCount: 39,
        studyCount: 85,
    },
    {
        id: 'QG5532-YQ',
        entityId: 'EID-QG5532-YQ',
        name: 'Clinical Trial Y',
        number: 'MSID1234',
        timezone: 'Asia/Jakarta',
        therapeuticArea: 'pulmonology',
        email: 'hlundym@nps.gov',
        siteLocales: ['es_ES', 'fr_FR', 'it_IT'],
        participantsLocales: ['fr_FR', 'de_DE'],
        address: {
            countryCode: 'ID',
            addressLine: '5698 Vermont Circle',
            city: 'Jagabaya Dua',
            region: 'Tennessee',
            postalCode: 'S4E 7G6',
            phone: [{ type: '24-hour', number: '109-459-6818' }],
        },
        userCount: 83,
        studyCount: 69,
    },
    {
        id: 'NJ3963-IY',
        entityId: 'EID-NJ3963-IY',
        name: 'Research Project X',
        number: 'MSID2345',
        timezone: 'America/Argentina/Buenos_Aires',
        therapeuticArea: 'cardiology',
        email: 'emendezn@tuttocitta.it',
        siteLocales: ['de_DE', 'it_IT'],
        participantsLocales: ['it_IT'],
        address: {
            countryCode: 'AR',
            addressLine: '8 Mitchell Alley',
            city: 'Brandsen',
            region: 'Utah',
            postalCode: 'U0H 6V9',
            phone: [{ type: 'daytime', number: '351-724-0442' }],
        },
        userCount: 6,
        studyCount: 29,
    },
    {
        id: 'SW6887-HV',
        entityId: 'EID-SW6887-HV',
        name: 'Study A',
        number: 'MSID3456',
        timezone: 'Asia/Urumqi',
        therapeuticArea: 'oncology',
        email: 'jkerswillo@discuz.net',
        siteLocales: ['fr_FR', 'en_US', 'es_ES'],
        participantsLocales: ['en_US', 'fr_FR'],
        address: {
            countryCode: 'CN',
            addressLine: '4352 Transport Point',
            city: 'Karmai',
            region: 'West Virginia',
            postalCode: 'O8A 4F2',
            phone: [{ type: '24-hour', number: '945-264-5552' }],
        },
        userCount: 71,
        studyCount: 18,
    },
];
