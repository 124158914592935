import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useLocalization } from 'libs.localization.react';
import { Button } from 'libs.nucleus.button';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Spinner } from 'libs.nucleus.spinner';
import { useBooleanState } from 'libs.react.hooks';
import { ImportPdfModal } from './import_pdf_modal.component';
import { usePdfUpload } from '../../hooks/use_pdf_upload.hook';
export const ImportPdfAction = ({ locale, asset, disabled = false, fileName }) => {
    const t = useLocalizeMessage();
    const [isImportModalOpen, openImportModal, closeImportModal] = useBooleanState(false);
    const { mutate: uploadPdf, isPending: isUploading } = usePdfUpload();
    const { localization, localizationEntry } = useLocalization(asset);
    const baseLocale = localizationEntry?.base_locale;
    const resourceId = baseLocale && localizationEntry?.localization_resources?.[baseLocale]?.study_pdf?.resourceId;
    const handleImport = useCallback(async (files) => {
        closeImportModal();
        const file = files[Object.keys(files)[0]]; // Only one file is allowed
        const payload = {
            data: {
                name: fileName,
            },
            metadata: {
                type: 'study-resource',
                resourceType: 'pdf',
            },
            locale,
            file,
        };
        if (resourceId) {
            uploadPdf({ payload, resourceId }, {
                onSuccess: async ({ data }) => {
                    const fileResult = data.data;
                    // update firebase
                    const translationId = fileResult.id;
                    await localization?.setMessages(locale, [
                        { namespace: 'study_pdf', messages: { resourceId: translationId } },
                    ]);
                    await localization?.setTranslationId(locale, resourceId, translationId);
                },
            });
        }
    }, [resourceId]);
    return (_jsxs(_Fragment, { children: [_jsx(Button, { variant: 'secondary', size: 'sm', onClick: openImportModal, label: t('Import PDF'), disabled: disabled, content: isUploading ? (_jsx("div", { className: 'py-1', children: _jsx(Spinner, { size: 'sm' }) })) : undefined }), isImportModalOpen && _jsx(ImportPdfModal, { onModalClose: closeImportModal, onHandleImport: handleImport })] }));
};
