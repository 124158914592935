import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Link } from 'libs.nucleus.link';
// todo: update columns & columns accessorKeys after api is ready
export const useWorkspaceSiteColumns = () => {
    const translate = useLocalizeMessage();
    const workspaceSiteColumns = useMemo(() => {
        return [
            {
                header: translate('Name'),
                accessorKey: 'name',
                cell: (context) => {
                    const { id, name } = context.row.original;
                    return (_jsx(Link, { LinkComponent: RouterLink, to: `/site-management/workspace-site/${id}`, children: name }));
                },
            },
            {
                header: translate('Site library ID'),
                accessorKey: 'number',
                // todo: add bold test style to column
            },
            {
                header: translate('Location'),
                accessorKey: 'address',
                cell: (context) => {
                    const { address } = context.row.original;
                    return `${address.city}, ${address.region}, ${address.postalCode}`;
                },
            },
            {
                header: translate('Region'),
                accessorKey: 'region',
            },
            {
                header: translate('Users'),
                accessorKey: 'userCount',
            },
            {
                header: translate('Studies'),
                accessorKey: 'studyCount',
            },
        ];
    }, []);
    return {
        workspaceSiteColumns,
    };
};
