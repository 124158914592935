import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { LocaleContext, useLocalizeMessage } from 'libs.nucleus.i18n';
import { ListInformation } from 'libs.react.components';
import { formatVisitWindow } from '../../helpers/participants.helpers';
export const VisitDetails = ({ visit }) => {
    const translate = useLocalizeMessage();
    const currentLocale = useContext(LocaleContext);
    return (_jsx(ListInformation, { dataTestId: 'visit-details-list-information', items: [
            {
                title: translate('Date'),
                description: formatVisitWindow({
                    windowStartDate: visit.c_window_start_date,
                    windowEndDate: visit.c_window_end_date,
                    currentLocale,
                }),
            },
            {
                title: translate('Activities'),
                description: visit.assignments,
            },
        ], useDefaultEmptyDescription: true }));
};
