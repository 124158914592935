import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { MOMRegion } from '../../types';
export const useDeploymentUtils = () => {
    const translate = useLocalizeMessage();
    const getRegionName = (region) => {
        switch (region) {
            case MOMRegion.CN:
                return translate('CN');
            case MOMRegion.EU:
                return translate('EU');
            case MOMRegion.US:
            case MOMRegion.LOCAL:
            default:
                return translate('US');
        }
    };
    const getFullRegionName = (region) => {
        switch (region) {
            case MOMRegion.CN:
                return translate('China');
            case MOMRegion.EU:
                return translate('Europe');
            case MOMRegion.US:
            case MOMRegion.LOCAL:
            default:
                return translate('United States');
        }
    };
    return { getRegionName, getFullRegionName };
};
