import { QueryClientContext, useQuery } from '@tanstack/react-query';
import { useContext, useMemo } from 'react';
import { AuthContext } from 'libs.react.auth';
import { useApiClient } from 'libs.react.contexts';
import { useDeploymentUtils } from '../use_deployment_utils';
export const useClusters = () => {
    const { entityId } = useContext(AuthContext);
    const momClient = useApiClient("mom" /* ApiClientService.MOM */);
    const queryClient = useContext(QueryClientContext);
    const { getFullRegionName } = useDeploymentUtils();
    const fetchClusters = async () => {
        const { data: response } = await momClient.get(`/v1/entities/${entityId}/clusters`);
        return Object.entries(response.data).reduce((acc, [name, cluster]) => {
            acc.push({ ...cluster, name: name });
            return acc;
        }, []);
    };
    const { data: clusters = [], isFetching, isLoading, } = useQuery({
        queryKey: ['entities', entityId, 'clusters'],
        queryFn: fetchClusters,
        enabled: !!entityId,
    });
    const invalidateClusters = async () => {
        await queryClient?.invalidateQueries({ queryKey: ['entities', entityId, 'clusters'] });
    };
    /**
     * We convert the different clusters into SelectOptions for the dropdown
     * filtering out the prod clusters and any cluster with duplicated regions
     */
    const testRegionOptions = useMemo(() => clusters.reduce((acc, cluster) => {
        if (!cluster.isProd && acc.every((option) => option.meta?.region !== cluster.region)) {
            acc.push({ label: getFullRegionName(cluster.region), value: cluster.name, meta: { region: cluster.region } });
        }
        return acc;
    }, []), [clusters]);
    /**
     * We convert the different clusters into SelectOptions for the dropdown
     * filtering out the non-prod clusters
     */
    const prodRegionOptions = useMemo(() => clusters.reduce((acc, cluster) => {
        if (cluster.isProd) {
            acc.push({ label: getFullRegionName(cluster.region), value: cluster.name });
        }
        return acc;
    }, []), [clusters]);
    return {
        clusters,
        invalidateClusters,
        isFetching,
        isLoading,
        testRegionOptions,
        prodRegionOptions,
    };
};
