import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Routes } from 'react-router-dom';
import { NotFoundPage } from 'libs.react.components';
import { EnvironmentsProvider } from './contexts/environments/environments.context';
import { WorkspaceSiteList } from './pages/workspace_site_list';
// context providers will be here
const ProvidersWrapper = ({ children }) => {
    return _jsx(EnvironmentsProvider, { children: children });
};
export const SiteManagementApp = () => {
    return (_jsx(ProvidersWrapper, { children: _jsxs(Routes, { children: [_jsx(Route, { index: true, path: 'dashboard', element: _jsx(WorkspaceSiteList, {}) }), _jsx(Route, { path: '*', element: _jsx(NotFoundPage, {}) })] }) }));
};
export { SITE_MANAGEMENT_FLAGS } from './constants/developer_flags.constants';
