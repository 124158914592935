import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { getLocaleMetaInfo } from 'libs.i18n-core';
import { LocalizationStatus } from 'libs.localization.common';
import { FormSections } from 'libs.nucleus.form_sections';
import { Locale, useLocalizeMessage } from 'libs.nucleus.i18n';
import { Table } from 'libs.nucleus.table';
import { ListInformation } from 'libs.react.components';
import { AssetVersion } from '../asset_version';
// @TODO: Get from localization library
export const AssetsInfoPanel = ({ title, listItems, locale, version = '1', localizationVersion, }) => {
    const translate = useLocalizeMessage();
    const columns = useMemo(() => {
        const cols = [
            { header: translate('Locale'), accessorKey: 'locale' },
            {
                header: translate('Language/script'),
                accessorKey: 'locale',
                cell: (value) => {
                    const { language } = getLocaleMetaInfo({ appLocale: Locale.en_US, targetLocale: value.getValue() });
                    return language;
                },
            },
            {
                header: translate('Country/Region'),
                accessorKey: 'locale',
                cell: (value) => {
                    const { region } = getLocaleMetaInfo({ appLocale: Locale.en_US, targetLocale: value.getValue() });
                    return region;
                },
            },
            {
                header: translate('Locale version'),
                accessorKey: 'version',
                cell: () => {
                    // return <Pill label={`v${row.original.version}`} backgroundColor='gray' />;
                    return localizationVersion ? (_jsx(AssetVersion, { currentVersion: localizationVersion })) : (_jsx(AssetVersion, { currentVersion: {
                            status: LocalizationStatus.NOT_STARTED,
                            version: 0,
                        } }));
                },
            },
        ];
        return cols;
    }, [locale, version, localizationVersion]);
    const data = [
        {
            locale: locale,
            version: version.toString(),
        },
    ];
    const formSections = [
        {
            title,
            content: (_jsxs("div", { className: 'flex flex-col gap-6', children: [_jsx(ListInformation, { items: listItems }), _jsx(Table, { data: data, columns: columns })] })),
        },
    ];
    return _jsx(FormSections, { sections: formSections });
};
