import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Duration } from 'luxon';
import { useCallback, useContext, useMemo, useState } from 'react';
import { FirebaseAppContext } from 'libs.firebase_react';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { StepIndicator } from 'libs.react.components';
import { ScheduleRemoteActivity } from './scheduled_remote_activity';
import { SelectActivityTypeStep } from './select_activity_type_step.component';
import { StudyConfigurationContext } from '../../../contexts';
import { useActivityUtils } from '../../../hooks';
import { ActivitiesTable } from '../activities_table';
import { EndingConditionType, StartingConditionType, useActivityDependencyUtils, } from '../schedule_timeline_scheduled_activities/activity_dependencies';
export const ScheduleTimelineAddActivityModal = ({ onCancel, onSave, onlyVisitActivities, }) => {
    const translate = useLocalizeMessage();
    const { getActivityFromResource } = useActivityUtils();
    const { activities, studyId } = useContext(StudyConfigurationContext);
    const { logEvent } = useContext(FirebaseAppContext);
    const [selectedActivityType, setSelectedActivityType] = useState(onlyVisitActivities ? 'visit' : '');
    const [step, setStep] = useState(onlyVisitActivities ? 2 : 1);
    const [isPristine, setIsPristine] = useState(true);
    const [selectedActivities, setSelectedActivities] = useState([]);
    const [scheduleRemoteActivityForm, setScheduleRemoteActivityForm] = useState({
        endConditions: [],
        selectedActivities: [],
        startConditions: [],
        type: '',
    });
    const activityDependencyUtils = useActivityDependencyUtils({
        isRemoteActivity: true,
    });
    const { startingConditions, endingConditions, specificStartTime, repetition, resetConditions, setSpecificStartTime, setRepetition, periodOptions, } = activityDependencyUtils;
    const [showSpecificStartTime, setShowSpecificStartTime] = useState(!!specificStartTime.time);
    const handleShowSpecificStartTimeChange = (show) => {
        setIsPristine(false);
        setShowSpecificStartTime(show);
    };
    const isSaveDisabled = useMemo(() => {
        if (isPristine) {
            return true;
        }
        if (showSpecificStartTime && !specificStartTime?.time) {
            return true;
        }
        if (scheduleRemoteActivityForm.type === 'recurring' && step === 4 && !repetition.repetitionEvery) {
            return true;
        }
        if (selectedActivityType === 'visit') {
            return selectedActivities.length === 0;
        }
        return (scheduleRemoteActivityForm.selectedActivities.length === 0 ||
            startingConditions.some((condition) => (condition.type === StartingConditionType.ACTIVITY_RESPONSE &&
                Object.values(condition.activityResponse).some((v) => !v.value)) ||
                (condition.type === StartingConditionType.DATE_RESPONSE &&
                    (condition.activityResponse.activity.value === '' || condition.activityResponse.screen.value === '')) ||
                (condition.type === StartingConditionType.VISIT_CONFIRMATION && !condition.visitId?.value) ||
                (condition.type === StartingConditionType.AFTER_ACTIVITY_COMPLETION && !condition.activityCompletion.value)) ||
            endingConditions.some((condition) => (condition.type === EndingConditionType.AFTER_ACTIVITY_COMPLETION && !condition.activityCompletion.value) ||
                (condition.type === EndingConditionType.AFTER_DURATION && !condition.duration) ||
                (condition.type === EndingConditionType.ACTIVITY_RESPONSE &&
                    Object.values(condition.activityResponse).some((v) => !v.value)) ||
                (condition.type === EndingConditionType.DATE_RESPONSE &&
                    (condition.activityResponse.activity.value === '' || condition.activityResponse.screen.value === '')) ||
                (condition.type === EndingConditionType.VISIT_CONFIRMATION && !condition.visitId?.value)) ||
            (repetition.count !== undefined && Number(repetition.count) < 1));
    }, [
        startingConditions,
        endingConditions,
        isPristine,
        selectedActivities.length,
        selectedActivityType,
        showSpecificStartTime,
        specificStartTime,
        scheduleRemoteActivityForm.selectedActivities,
        repetition,
    ]);
    const handleSave = () => {
        if (selectedActivityType === 'visit') {
            const newActivities = selectedActivities.map((activityResource, index) => {
                logEvent('study_activity_added', { study_id: studyId, activity_name: activityResource.data.full_name });
                return getActivityFromResource(activityResource, activities.length + index + 1);
            });
            onSave('visit', newActivities);
        }
        else {
            const startTime = showSpecificStartTime && specificStartTime?.time
                ? Duration.fromObject({ hours: specificStartTime.time.hour, minute: specificStartTime.time.minute }).toISO()
                : undefined;
            const remoteActivityForm = {
                ...scheduleRemoteActivityForm,
                startConditions: startingConditions,
                endConditions: endingConditions,
                specificStartTime: startTime,
                repetition,
            };
            onSave('remote', remoteActivityForm);
        }
    };
    const handleContinue = () => {
        if ((selectedActivityType === 'visit' && step !== 2) || (selectedActivityType === 'remote' && step !== 4)) {
            setStep(step + 1);
        }
    };
    const onGoBack = () => {
        setStep(step - 1);
    };
    const onActivitiesChange = useCallback((activities) => {
        setSelectedActivities(activities);
        setIsPristine(false);
    }, []);
    const saveButton = {
        label: translate('Done'),
        onClick: handleSave,
        disabled: isSaveDisabled,
    };
    const continueButton = {
        label: translate('Continue'),
        onClick: handleContinue,
        disabled: !selectedActivityType ||
            (selectedActivityType === 'remote' && step === 2 && !scheduleRemoteActivityForm.type) ||
            (step === 3 && !scheduleRemoteActivityForm.selectedActivities.length),
    };
    const cancelButton = {
        label: translate('Cancel'),
        onClick: onCancel,
    };
    const backButton = { label: translate('Back'), onClick: onGoBack };
    const onFormChange = (newForm) => {
        setScheduleRemoteActivityForm((oldScheduleActivityForm) => ({ ...oldScheduleActivityForm, ...newForm }));
    };
    const onTypeSelect = (type) => {
        setScheduleRemoteActivityForm((oldScheduleActivityForm) => ({
            ...oldScheduleActivityForm,
            type,
            selectedActivities: [],
        }));
        resetConditions(type);
        setShowSpecificStartTime(false);
        setSpecificStartTime({ time: null });
        setRepetition({ repetitionEvery: '1', repetitionEveryPeriod: periodOptions[1] });
    };
    const primaryButton = !onlyVisitActivities &&
        (!selectedActivityType ||
            (selectedActivityType === 'visit' && step !== 2) ||
            (selectedActivityType === 'remote' && step !== 4))
        ? continueButton
        : saveButton;
    const modalSubtitle = useMemo(() => {
        if (onlyVisitActivities) {
            return '';
        }
        return selectedActivityType
            ? translate('Step {step} of {totalSteps}', { step, totalSteps: selectedActivityType === 'visit' ? 2 : 4 })
            : translate('Step 1');
    }, [onlyVisitActivities, selectedActivityType, step]);
    return (_jsxs(ModalWindow, { title: translate('Add activity'), subtitle: modalSubtitle, isOpen: true, closeWindow: onCancel, footerPrimaryActionButton: primaryButton, footerSecondaryActionButtons: step === 1 || onlyVisitActivities ? [cancelButton] : [cancelButton, backButton], footerCenterContent: selectedActivityType && !onlyVisitActivities ? (_jsx(StepIndicator, { totalSteps: selectedActivityType === 'visit' ? 2 : 4, currentStep: step })) : undefined, width: 'full', children: [step === 1 && !onlyVisitActivities && (_jsx(SelectActivityTypeStep, { selectedActivityType: selectedActivityType, onSelectedActivityTypeChange: setSelectedActivityType })), step === 2 && selectedActivityType === 'visit' && (_jsx("section", { className: 'min-h-[30rem]', children: _jsx(ActivitiesTable, { onActivitiesChange: onActivitiesChange }) })), step > 1 && selectedActivityType === 'remote' && (_jsx(ScheduleRemoteActivity, { step: step, scheduleRemoteActivityForm: scheduleRemoteActivityForm, onFormChange: onFormChange, onTypeSelect: onTypeSelect, activityDependencyUtils: activityDependencyUtils, onPristineChange: setIsPristine, showSpecificStartTime: showSpecificStartTime, onSpecificStartTimeChange: handleShowSpecificStartTimeChange }))] }));
};
