import { jsx as _jsx } from "react/jsx-runtime";
import { ActivityEcoaView } from './activity_ecoa/activity_ecoa_view';
import { ActivityStandardView } from './activity_standard_view';
export const ActivityView = ({ visitActivity, setIsActivityModalClosed }) => {
    const activity = visitActivity.activity.c_group_task.c_assignment;
    if (activity.c_type === 'web_form') {
        return _jsx(ActivityEcoaView, { visitActivity: visitActivity, setIsActivityModalClosed: setIsActivityModalClosed });
    }
    return _jsx(ActivityStandardView, { visitActivity: visitActivity, setIsActivityModalClosed: setIsActivityModalClosed });
};
