import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { PageHeader, PageWrapper } from 'libs.react.components';
import { useBooleanState } from 'libs.react.hooks';
import { WorkspaceSiteCreationModal } from '../../components/workspace_site_creation_modal';
import { WorkspaceSitesTable } from '../../components/workspace_sites_table';
export const WorkspaceSiteList = () => {
    const translate = useLocalizeMessage();
    const [isCreationModalOpen, setCreationModalOpen, setCreationModalClose] = useBooleanState();
    // todo: add api call
    // todo: add bulk upload site functionality & from
    const headerOptions = [
        {
            name: translate('Bulk upload sites'),
            onClick: () => { },
            variant: 'secondary',
            disabled: true,
        },
        {
            name: translate('Create site'),
            onClick: setCreationModalOpen,
            variant: 'primary',
        },
    ];
    // todo: add bulk upload site functionality
    return (_jsxs(PageWrapper, { children: [_jsx(PageHeader, { title: translate('Sites'), subtitle: translate('This is your workspace sites library. A workspace library is the list of sites that are global applied to your entire entity instead of just individual studies.'), options: headerOptions }), _jsx(WorkspaceSitesTable, {}), _jsx(WorkspaceSiteCreationModal, { isOpen: isCreationModalOpen, onClose: setCreationModalClose })] }));
};
