import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LinkNode, AutoLinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoLinkPlugin, createLinkMatcherWithRegExp } from '@lexical/react/LexicalAutoLinkPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin as RichContentPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ParagraphNode, TextNode } from 'lexical';
import { useMemo } from 'react';
import { Description } from './description';
import { ErrorMessage } from './error_message';
import { Label } from './label';
import { RichContentContainer } from './rich_content_container';
import { AlignmentContextProvider } from '../contexts/alignment_context';
import { ImagesContextProvider } from '../contexts/images_context';
import { LinkTargetProvider } from '../contexts/link_target_context';
import { FormatLink } from '../formatters';
import { CustomAutoLinkNode, CustomLinkNode, CustomParagraphNode, CustomListNode, CustomListItemNode, CustomTextNode, ImageNode, } from '../nodes';
import { CustomOnChange, ImagesPlugin } from '../plugins';
import exampleTheme from '../theme';
import { EMAIL_REGEX, URL_REGEX, getInputClassnames, hasComponent, validateUrl } from '../utils';
const sizeClasses = {
    base: 'w-input-base',
    xs: 'w-input-xs self-stretch',
    sm: 'w-input-sm self-stretch',
    lg: 'w-input-lg self-stretch',
    full: 'w-input-full self-stretch',
};
export const getInitialConfig = (hasLinkSupport, retainTarget) => ({
    namespace: 'editor',
    theme: exampleTheme,
    nodes: [
        ...(hasLinkSupport
            ? [
                CustomLinkNode,
                CustomAutoLinkNode,
                { replace: AutoLinkNode, with: (node) => new CustomAutoLinkNode(node.getURL()) },
                {
                    replace: LinkNode,
                    with: (node) => new CustomLinkNode(node.getURL(), retainTarget),
                },
            ]
            : []),
        CustomParagraphNode,
        CustomTextNode,
        CustomListNode,
        CustomListItemNode,
        ImageNode,
        {
            replace: TextNode,
            with: (node) => new CustomTextNode(node.getTextContent()),
        },
        {
            replace: ParagraphNode,
            with: () => new CustomParagraphNode(),
        },
        {
            replace: ListNode,
            with: (node) => new CustomListNode(node.getListType(), node.getStart()),
        },
        {
            replace: ListItemNode,
            with: (node) => new CustomListItemNode(node.getValue(), node.getChecked()),
        },
    ],
    onError: (error) => {
        throw error;
    },
});
const MATCHERS = [
    createLinkMatcherWithRegExp(URL_REGEX, (text) => {
        return text;
    }),
    createLinkMatcherWithRegExp(EMAIL_REGEX, (text) => {
        return `mailto:${text}`;
    }),
];
export const RichContentEditor = ({ type = 'input', dataTestId, label, description, id, required, errorMessage, onChange, width = 'base', value, children, placeholder = null, containerRef, retainTarget = false, }) => {
    const size = sizeClasses[width];
    const containerClassName = `relative box-border max-w-full font-sans inline-block ${size}`;
    const showDescription = width !== 'xs' && width !== 'sm' && description;
    const hasLinkSupport = useMemo(() => hasComponent(children, FormatLink), [children]);
    const initialConfig = useMemo(() => getInitialConfig(hasLinkSupport, retainTarget), [hasLinkSupport, retainTarget]);
    return (_jsx(LexicalComposer, { initialConfig: initialConfig, children: _jsx(ImagesContextProvider, { initialValue: value, children: _jsx(AlignmentContextProvider, { initialValue: value, children: _jsx(LinkTargetProvider, { retainTarget: retainTarget, children: _jsxs(RichContentContainer, { className: containerClassName, initialValue: value, "data-testid": dataTestId, containerRef: containerRef, children: [_jsx(Label, { required: required, id: id, children: label }), showDescription && _jsx(Description, { children: description }), _jsx(RichContentPlugin, { contentEditable: _jsx(ContentEditable, { ariaRequired: required, ariaMultiline: type === 'textarea', ariaLabel: label, ariaLabelledBy: `${id}-label`, className: getInputClassnames(!!errorMessage, type) }), ErrorBoundary: LexicalErrorBoundary, placeholder: placeholder }), _jsx(ListPlugin, {}), hasLinkSupport && _jsx(LinkPlugin, { validateUrl: validateUrl }), hasLinkSupport && _jsx(AutoLinkPlugin, { matchers: MATCHERS }), _jsx(ImagesPlugin, {}), children, !!errorMessage && (_jsx(ErrorMessage, { id: id, size: size, children: errorMessage })), _jsx(CustomOnChange, { onChange: onChange, id: id })] }) }) }) }) }));
};
