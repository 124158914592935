import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { Breadcrumbs } from 'libs.nucleus.breadcrumbs';
import { Button } from 'libs.nucleus.button';
import { Spinner } from 'libs.nucleus.spinner';
import { H3, H6 } from 'libs.nucleus.typography';
export const PageHeader = ({ breadcrumbPages = [], customOptions, options, showOptions = true, size = 'default', subtitle, title, }) => {
    return (_jsxs("section", { "data-testid": 'page-header', className: `flex flex-col w-full gap-2 ${size === 'default' ? 'mb-6' : 'mb-2'}`, children: [breadcrumbPages.length > 0 && _jsx(Breadcrumbs, { pages: breadcrumbPages, LinkComponent: Link }), _jsxs("div", { className: 'flex w-full items-center justify-between gap-4', children: [_jsxs("div", { children: [typeof title === 'string' ? (_jsx(H3, { className: 'text-4xl font-light text-text-primary', dataTestId: 'page-header-title', children: title })) : (title), subtitle && _jsx(H6, { className: 'text-neutral-600 pt-2', children: subtitle })] }), showOptions && (_jsxs("div", { className: 'flex self-start items-center', children: [customOptions, options &&
                                options.map((option) => (_jsx("div", { className: 'ml-2', children: _jsx(Button, { disabled: option.disabled, label: option.name, onClick: option.onClick, variant: option.variant || 'secondary', content: option.isLoading ? (_jsxs(_Fragment, { children: [option.name, _jsx("div", { className: 'ml-1 py-1', children: _jsx(Spinner, { size: 'sm' }) })] })) : undefined }) }, option.name.toString())))] }))] })] }));
};
