import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { ToastNotification, ToastNotificationContext } from 'libs.nucleus.notification';
const DEFAULT_TIMEOUT = 10000;
export const useToastNotification = () => {
    const { add, remove } = useContext(ToastNotificationContext);
    /**
     * adds a notification to the toast notification list. pass a timeout of 0 to disable the timeout. error toasts will not be removed automatically
     * @param notification
     * @param timeout
     */
    const addNotification = (notification, timeout = DEFAULT_TIMEOUT) => {
        const id = add(_jsx(ToastNotification, Object.assign({}, notification)));
        if (timeout > 0 && notification.type !== 'error') {
            setTimeout(() => {
                remove(id);
            }, timeout);
        }
    };
    return {
        addNotification,
    };
};
