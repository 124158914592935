import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { QueryClientContext } from '@tanstack/react-query';
import axios from 'axios';
import { DateTime } from 'luxon';
import { useContext, useState } from 'react';
import { PlainDate } from 'temporal-luxon';
import { DatePicker } from 'libs.nucleus.date_picker';
import { FormSections } from 'libs.nucleus.form_sections';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Icon } from 'libs.nucleus.icon';
import { Input } from 'libs.nucleus.input';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { Spinner } from 'libs.nucleus.spinner';
import { TimePicker } from 'libs.nucleus.time_picker';
import { Text } from 'libs.nucleus.typography';
import { AuthContext } from 'libs.react.auth';
import { FormattedDateTime } from 'libs.react.components';
import { useToastNotification } from 'libs.react.hooks';
import { calculateServerTimeDiff, convertAbsoluteTimeToISO, convertRelativeTimeToISO, getUTCOffset, } from './server_time_modal.utils';
import { StudyConfigurationContext } from '../../contexts';
const defaultTimeOption = {
    time: null,
    timeZone: null,
};
export const ServerTimeModal = ({ onClose, studyEnv, serverDateTime }) => {
    const translate = useLocalizeMessage();
    const queryClient = useContext(QueryClientContext);
    const { entityId } = useContext(AuthContext);
    const { studyId } = useContext(StudyConfigurationContext);
    const { addNotification } = useToastNotification();
    const utcOffset = getUTCOffset();
    const currentServerDate = PlainDate.from(DateTime.fromISO(serverDateTime).toISODate() ?? new Date().toISOString());
    const [isLoading, setIsLoading] = useState(false);
    const [isPristine, setIsPristine] = useState(true);
    const [isRelative, setIsRelative] = useState(true);
    const [changeServerTimeForm, setChangeServerTimeForm] = useState({
        date: currentServerDate,
        timePickerValue: defaultTimeOption,
        days: 0,
        hours: 0,
        minutes: 0,
    });
    const saveDisabled = isPristine ||
        isLoading ||
        (isRelative
            ? !changeServerTimeForm.days && !changeServerTimeForm.hours && !changeServerTimeForm.minutes
            : !changeServerTimeForm.timePickerValue.time || !changeServerTimeForm.timePickerValue.timeZone);
    const saveServerTime = async () => {
        if (!studyEnv || !changeServerTimeForm.date || saveDisabled) {
            return;
        }
        try {
            setIsLoading(true);
            const cortexClusterUrl = studyEnv.cortexURL;
            const isoDate = isRelative
                ? convertRelativeTimeToISO(serverDateTime, changeServerTimeForm.days, changeServerTimeForm.hours, changeServerTimeForm.minutes)
                : convertAbsoluteTimeToISO(changeServerTimeForm.date, changeServerTimeForm.timePickerValue);
            const { data } = await axios.post(`${cortexClusterUrl}/settime/${isoDate}`);
            if (data.shift === '+0m') {
                throw new Error(translate('Server time was not updated, the selected server time is in the past'));
            }
            await queryClient?.invalidateQueries({
                queryKey: ['entities', entityId, 'studies', studyId, 'environment', `${studyEnv.id}`, 'serverTime'],
            });
            addNotification({
                title: translate('Server time was updated successfully.'),
                type: 'success',
            });
            onClose();
        }
        catch (error) {
            addNotification({
                title: translate('Failed to update server time'),
                subtitle: error.message,
                type: 'error',
            });
        }
        finally {
            setIsLoading(false);
        }
    };
    const savePrimaryButton = {
        label: translate('Update'),
        onClick: saveServerTime,
        disabled: saveDisabled,
    };
    const cancelSecondaryButton = {
        label: translate('Cancel'),
        onClick: onClose,
    };
    const handleDateChange = (newDate) => {
        setChangeServerTimeForm((oldForm) => ({ ...oldForm, date: newDate }));
        setIsPristine(false);
    };
    const handleTimeChange = (value) => {
        setChangeServerTimeForm((oldForm) => ({ ...oldForm, timePickerValue: value }));
        setIsPristine(false);
    };
    const handleRelativeTimeChange = (event, type) => {
        const value = parseInt(event.target.value);
        if (isNaN(value) || value < 0) {
            return;
        }
        setChangeServerTimeForm((oldForm) => ({ ...oldForm, [type]: value }));
        setIsPristine(false);
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey && !saveDisabled) {
            saveServerTime();
        }
    };
    const onSubmit = (event) => {
        event.preventDefault();
        saveServerTime();
    };
    return (_jsxs(ModalWindow, { title: translate('Change server time'), isOpen: true, closeWindow: onClose, footerPrimaryActionButton: savePrimaryButton, footerSecondaryActionButtons: [cancelSecondaryButton], width: 'full', children: [isLoading && _jsx(Spinner, { wrapper: 'full' }), _jsx("form", { autoComplete: 'off', onSubmit: onSubmit, onKeyUp: handleEnterPress, children: _jsx(FormSections, { sections: [
                        {
                            title: translate('Change server time'),
                            content: (_jsxs("div", { className: 'grid gap-y-8 max-w-[38rem]', children: [_jsxs("section", { children: [_jsx(Text, { className: 'nucleus-text-text-secondary', children: translate('Current server time') }), _jsxs("div", { className: 'flex items-center gap-x-8', children: [_jsxs(Text, { className: 'text-xl', children: [_jsx(FormattedDateTime, { date: serverDateTime }), " ", translate('(UTC{offset})', { offset: utcOffset })] }), _jsxs("div", { className: 'bg-green-700 rounded-lg p-2 text-white flex justify-between items-center text-xs gap-x-1', children: [_jsx("span", { children: _jsx(Icon, { name: 'time', color: 'white', size: 'sm' }) }), _jsx("span", { children: calculateServerTimeDiff(serverDateTime) })] })] })] }), _jsxs("section", { children: [_jsxs("div", { className: 'flex justify-between items-center', children: [_jsx(Text, { className: 'nucleus-text-text-secondary', children: translate('New server time') }), _jsx("button", { onClick: (e) => {
                                                            e.preventDefault();
                                                            setIsRelative(!isRelative);
                                                        }, className: 'text-xs nucleus-text-primary-500 outline-none', children: isRelative ? translate('Change to absolute') : translate('Change to relative') })] }), _jsx("div", { className: 'mt-2', children: isRelative ? (_jsxs("div", { className: 'flex gap-4', children: [_jsx(Input, { id: 'new-server-days', value: changeServerTimeForm.days, onChange: (event) => handleRelativeTimeChange(event, 'days'), label: translate('Days'), width: 'xs', type: 'number' }), _jsx(Input, { id: 'new-server-hours', value: changeServerTimeForm.hours, onChange: (event) => handleRelativeTimeChange(event, 'hours'), label: translate('Hours'), width: 'xs', type: 'number' }), _jsx(Input, { id: 'new-server-minutes', value: changeServerTimeForm.minutes, onChange: (event) => handleRelativeTimeChange(event, 'minutes'), label: translate('Minutes'), width: 'xs', type: 'number' })] })) : (_jsxs("div", { className: 'grid gap-4', children: [_jsx(DatePicker, { id: 'new-server-date', value: changeServerTimeForm.date, label: translate('Date'), onChange: handleDateChange, required: true, startDate: currentServerDate }), _jsx(TimePicker, { required: true, showTimeZone: true, onChange: (event) => handleTimeChange(event), value: changeServerTimeForm.timePickerValue })] })) })] }), _jsx("section", { children: _jsx("p", { className: 'text-sm nucleus-text-text-secondary italic', children: translate('Note: Time Travel functionality only allows traveling forward in time. If you advance server time and then need to go backwards, you will need to create a new sandbox. You can then start a new Time Travel to go forward to the time you need.') }) })] })),
                        },
                    ] }) })] }));
};
