import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Checkbox } from 'libs.nucleus.checkbox';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { Text } from 'libs.nucleus.typography';
export const SingleExportTranslationModal = ({ onModalClose, onHandleExport }) => {
    const translate = useLocalizeMessage();
    const [isScreens, setIsScreens] = useState(true);
    const [isStrings, setIsStrings] = useState(true);
    const closeButton = {
        label: translate('Cancel'),
        onClick: onModalClose,
    };
    const doneButton = {
        label: translate('Export'),
        disabled: !isScreens && !isStrings,
        onClick: () => {
            const payload = [];
            isScreens && payload.push('screens');
            isStrings && payload.push('strings');
            onHandleExport(payload);
        },
    };
    return (_jsx(ModalWindow, { title: translate('Export screens and strings'), isOpen: true, closeWindow: onModalClose, footerPrimaryActionButton: doneButton, footerSecondaryActionButtons: [closeButton], position: 'top', children: _jsxs("div", { className: 'px-6 py-2 flex flex-col gap-5', children: [_jsx(Text, { as: 'h6', children: translate('Content to export') }), _jsx(Checkbox, { description: translate('Base language strings in .json files'), label: translate('Strings'), onChange: setIsStrings, checked: isStrings }), _jsx(Checkbox, { description: translate('Base language screenshots in screen report .pdf'), label: translate('Screens'), onChange: setIsScreens, checked: isScreens })] }) }));
};
