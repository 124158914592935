import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { FormSections } from 'libs.nucleus.form_sections';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Input } from 'libs.nucleus.input';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { Select } from 'libs.nucleus.select';
// import { useApiClient, ApiClientService } from 'libs.react.contexts';
import { useToastNotification } from 'libs.react.hooks';
import { validateFormFieldOnBlur, validateFormFieldOnChange, timezoneOptions, countryOptions, localesOptions, stateOptions, therapeuticAreasOptions, } from './helpers';
export const WorkspaceSiteCreationModal = ({ isOpen, onClose }) => {
    const formInitValue = {
        name: '',
        number: '',
        therapeuticArea: '',
        timezone: '',
        siteLocales: [],
        email: '',
        phone: '',
        countryCode: '',
        addressLine: '',
        city: '',
        region: '',
        postalCode: '',
    };
    // const apiClient = useApiClient(ApiClientService.MOM);
    const translate = useLocalizeMessage();
    const { addNotification } = useToastNotification();
    const [form, setForm] = useState(formInitValue);
    const [errors, setErrors] = useState({});
    const getSelectValue = (options, value) => {
        return isEmpty(value) ? { label: '', value: '' } : options.find((option) => option.value === value);
    };
    const getMultiselectValue = (options, value) => {
        return isEmpty(value) ? [] : options.filter((option) => value.includes(option.value));
    };
    const validateFormOnSubmit = () => {
        const errors = {};
        Object.keys(form).forEach((key) => {
            const errorOnChange = validateFormFieldOnChange(key, form[key]);
            const errorOnBlur = validateFormFieldOnBlur(key, form[key]);
            const error = errorOnBlur || errorOnChange;
            if (error) {
                errors[key] = translate(error);
            }
        });
        setErrors(errors);
        return !isEmpty(errors);
    };
    const handleInputFieldChange = (event) => {
        const { name, value } = event.target;
        const error = validateFormFieldOnChange(name, value);
        setForm((prevState) => ({ ...prevState, [name]: value }));
        setErrors((prevState) => ({ ...prevState, [name]: error ? translate(error) : undefined }));
    };
    const handleInputFieldBlur = (event) => {
        const { name, value } = event.target;
        const error = validateFormFieldOnBlur(name, value);
        setErrors((prevState) => ({ ...prevState, [name]: error ? translate(error) : undefined }));
    };
    const handleSelectFieldChange = (name, value) => {
        const error = validateFormFieldOnChange(name, value.value);
        setForm((prevState) => ({ ...prevState, [name]: value.value }));
        setErrors((prevState) => ({ ...prevState, [name]: error ? translate(error) : undefined }));
    };
    const handleMultiselectFieldChange = (name, value) => {
        const preparedValue = value.map((option) => option.value);
        const error = validateFormFieldOnChange(name, preparedValue);
        setForm((prevState) => ({ ...prevState, [name]: preparedValue }));
        setErrors((prevState) => ({ ...prevState, [name]: error ? translate(error) : undefined }));
    };
    const handleClose = () => {
        setForm(formInitValue);
        setErrors({});
        onClose();
    };
    const handleSubmitForm = async () => {
        const errors = validateFormOnSubmit();
        if (errors) {
            return;
        }
        try {
            const payload = {
                name: form.name,
                number: form.number,
                therapeuticArea: form.therapeuticArea,
                timezone: form.timezone,
                siteLocales: form.siteLocales,
                email: form.email,
                address: {
                    countryCode: form.countryCode,
                    addressLine: form.addressLine,
                    city: form.city,
                    region: form.region,
                    postalCode: form.postalCode,
                    phone: [
                        {
                            type: '24-hour',
                            number: form.phone,
                        },
                    ],
                },
            };
            console.debug('# payload', payload);
            // todo: get current environmentId
            // const response = await apiClient.post(`/entities/${environmentId}/sites`, payload);
            // todo: show notification on success
            if (payload) {
                addNotification({
                    title: '',
                    subtitle: translate(`Site record created`),
                    type: 'success',
                });
            }
        }
        catch (error) {
            // todo: improve error handel; add notification
            console.error('Error creating site', error);
        }
        finally {
            handleClose();
        }
    };
    const getDetailsSectionContent = () => {
        return (_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx(Input, { id: 'name', dataTestId: 'name', name: 'name', label: translate('Name'), width: 'lg', required: true, hasError: !!errors.name, errorMessage: errors.name, value: form.name, onChange: handleInputFieldChange, onBlur: handleInputFieldBlur }), _jsx(Input, { id: 'number', dataTestId: 'number', name: 'number', label: translate('Site library ID'), width: 'lg', hasError: !!errors.number, errorMessage: errors.number, value: form.number, onChange: handleInputFieldChange, onBlur: handleInputFieldBlur }), _jsx(Select, { dataTestId: 'therapeutic-area', label: translate('Therapeutic area'), options: therapeuticAreasOptions, width: 'lg', hasError: !!errors.therapeuticArea, errorMessage: errors.therapeuticArea, value: getSelectValue(therapeuticAreasOptions, form.therapeuticArea), onChange: (value) => handleSelectFieldChange('therapeuticArea', value) }), _jsx(Select, { dataTestId: 'timezone', label: translate('Time zone'), options: timezoneOptions, width: 'lg', hasError: !!errors.therapeuticArea, errorMessage: errors.therapeuticArea, value: getSelectValue(timezoneOptions, form.timezone), onChange: (value) => handleSelectFieldChange('timezone', value) }), _jsx(Select, { multiple: true, dataTestId: 'site-locale', label: translate('Locale'), options: localesOptions, width: 'lg', hasError: !!errors.siteLocales, errorMessage: errors.siteLocales, value: getMultiselectValue(localesOptions, form.siteLocales), onChange: (value) => handleMultiselectFieldChange('siteLocales', value) })] }));
    };
    const getContactInformationSectionContent = () => {
        return (_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx(Input, { required: true, id: 'email', dataTestId: 'email', name: 'email', label: translate('Email'), width: 'lg', hasError: !!errors.email, errorMessage: errors.email, value: form.email, onChange: handleInputFieldChange, onBlur: handleInputFieldBlur }), _jsx(Input, { required: true, id: 'phone', dataTestId: 'phone', name: 'phone', label: translate('Phone'), width: 'lg', hasError: !!errors.phone, errorMessage: errors.phone, value: form.phone, onChange: handleInputFieldChange, onBlur: handleInputFieldBlur }), _jsx(Select, { required: true, dataTestId: 'country-code', label: translate('Country'), width: 'lg', hasError: !!errors.countryCode, errorMessage: errors.countryCode, options: countryOptions, value: getSelectValue(countryOptions, form.countryCode), onChange: (value) => handleSelectFieldChange('countryCode', value) }), _jsx(Input, { required: true, id: 'addressLine', dataTestId: 'address-line', name: 'addressLine', label: translate('Address line'), width: 'lg', hasError: !!errors.addressLine, errorMessage: errors.addressLine, onChange: handleInputFieldChange }), _jsx(Input, { required: true, id: 'city', dataTestId: 'city', name: 'city', label: translate('City'), width: 'lg', hasError: !!errors.city, errorMessage: errors.city, onChange: handleInputFieldChange, onBlur: handleInputFieldBlur }), _jsx(Select, { required: true, dataTestId: 'region', label: translate('Region'), width: 'lg', options: stateOptions, hasError: !!errors.region, errorMessage: errors.region, value: getSelectValue(stateOptions, form.region), onChange: (value) => handleSelectFieldChange('region', value) }), _jsx(Input, { id: 'postalCode', dataTestId: 'postal-code', name: 'postalCode', label: translate('ZIP code'), width: 'lg', hasError: !!errors.postalCode, errorMessage: errors.postalCode, value: form.postalCode, onChange: handleInputFieldChange, onBlur: handleInputFieldBlur })] }));
    };
    const formSections = [
        {
            title: translate('Details'),
            content: getDetailsSectionContent(),
        },
        {
            title: translate('Contact information'),
            content: getContactInformationSectionContent(),
        },
    ];
    return (_jsx(_Fragment, { children: _jsx(ModalWindow, { isOpen: isOpen, dataTestId: 'create-site-modal', title: translate('Create site'), subtitle: translate('Create workspace site'), closeWindow: onClose, width: 'full', footerPrimaryActionButton: {
                id: 'create-site-button',
                dataTestId: 'create-site-button',
                disabled: false,
                label: translate('Create site'),
                content: undefined, // todo: add isSaving state // isSaving ? <Spinner size='sm' /> : undefined
                onClick: handleSubmitForm,
            }, footerSecondaryActionButtons: [
                {
                    id: 'cancel-create-site-button',
                    dataTestId: 'cancel-create-site-button',
                    label: translate('Cancel'),
                    onClick: handleClose,
                },
            ], children: _jsx("form", { className: 'mt-10 mb-10', children: _jsx(FormSections, { sections: formSections }) }) }) }));
};
