import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Pill } from 'libs.nucleus.pill';
import { LibraryResourceStatus } from 'libs.react.types';
export const StudyVersionStatusPill = ({ status }) => {
    const translate = useLocalizeMessage();
    const statusMap = {
        [LibraryResourceStatus.APPROVED]: {
            color: 'green',
            text: translate('Approved'),
        },
        [LibraryResourceStatus.PUBLISHED]: {
            color: 'primary',
            text: translate('Ready for review'),
        },
        [LibraryResourceStatus.DRAFT]: {
            color: 'gray',
            text: translate('Draft'),
        },
    };
    const pillInfo = useMemo(() => {
        return status ? statusMap[status] : statusMap[LibraryResourceStatus.PUBLISHED];
    }, [status]);
    return useMemo(() => {
        return _jsx(Pill, { label: pillInfo.text, iconBackgroundColor: pillInfo.color, variant: 'status' });
    }, [pillInfo]);
};
