import { jsx as _jsx } from "react/jsx-runtime";
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Table } from 'libs.nucleus.table';
import { useWorkspaceSiteColumns } from './workspace_sites_columns.hook';
import { workspace_sites_mock_data } from '../../workspace_sites.mock';
// todo: q: clarify placeholders for empty state, search files, hints, etc.
// todo: q: are we plan to use be_search? - yes
// todo: q: are we plan to use be_pagination? - yes
export const WorkspaceSitesTable = () => {
    const translate = useLocalizeMessage();
    const { workspaceSiteColumns } = useWorkspaceSiteColumns();
    return (_jsx(Table, { hasPagination: true, useInternalSearch: true, isLoading: false, columns: workspaceSiteColumns, data: workspace_sites_mock_data, initialPageSize: 10, labelNoResults: translate('No Sites'), paginationNavigationScreenReaderLabel: translate('Sites table navigation') }));
};
