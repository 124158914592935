import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LocalizationStatus } from 'libs.localization.common';
import { Icon } from 'libs.nucleus.icon';
const localeStatusMap = {
    [LocalizationStatus.READY]: {
        color: 'green',
        icon: 'statusReady',
        size: 'w-8',
    },
    [LocalizationStatus.IN_PROGRESS]: {
        color: 'orange',
        icon: 'statusInProgress',
        size: 'w-8',
    },
    [LocalizationStatus.IN_REVIEW]: {
        color: 'yellowPale',
        icon: 'statusInReview',
        size: 'w-8',
    },
    [LocalizationStatus.NOT_STARTED]: {
        color: 'redPale',
        icon: 'statusNotStarted',
        size: 'w-7',
    },
};
export const AssetVersion = ({ currentVersion }) => {
    if (!currentVersion ||
        !currentVersion.status ||
        (!currentVersion.version && !currentVersion.status) ||
        currentVersion.status === LocalizationStatus.NOT_AVAILABLE) {
        return '-';
    }
    const { status, version } = currentVersion;
    const showVersion = status !== LocalizationStatus.NOT_STARTED && version > 0;
    return (_jsxs("div", { className: `${localeStatusMap[status].size} relative m-auto text-center`, children: [_jsx(Icon, { name: localeStatusMap[status].icon, color: localeStatusMap[status].color, size: 'full', "data-testid": `locale-status-icon-${status.replace(' ', '-').toLowerCase()}` }), showVersion && (_jsxs("div", { className: 'text-xs font-medium text-gray-700 absolute -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4', children: ["v", version] }))] }));
};
