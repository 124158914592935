import { ListNode } from '@lexical/list';
import { isHTMLElement } from 'lexical';
export class CustomListNode extends ListNode {
    static getType() {
        return 'custom_list_node';
    }
    static clone(node) {
        return new CustomListNode(node.__listType, node.__start, node.__key);
    }
    static importJSON(node) {
        return super.importJSON(node);
    }
    exportJSON() {
        return Object.assign(Object.assign({}, super.exportJSON()), { type: 'custom_list_node', version: 1 });
    }
    exportDOM(editor) {
        const { element } = super.exportDOM(editor);
        if (element && isHTMLElement(element)) {
            element.removeAttribute('class');
        }
        return { element };
    }
}
