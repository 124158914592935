import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from 'react';
import { FormSections } from 'libs.nucleus.form_sections';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { Select } from 'libs.nucleus.select';
import { Spinner } from 'libs.nucleus.spinner';
import { useToastNotification } from 'libs.react.hooks';
import { StudyConfigurationContext, StudyTestPlanContext } from '../../contexts';
export const ExecuteTestPlanModal = ({ testPlanId, onClose, onSave, tests }) => {
    const translate = useLocalizeMessage();
    const { addNotification } = useToastNotification();
    const { executeTestPlan } = useContext(StudyTestPlanContext);
    const { currentStudy } = useContext(StudyConfigurationContext);
    const [isLoading, setIsLoading] = useState(false);
    const [studyTestForm, setStudyTestForm] = useState({
        sandbox: {
            label: '-select-',
            value: '',
            meta: {
                version: undefined,
            },
        },
    });
    const saveDisabled = useMemo(() => {
        return !studyTestForm.sandbox.value || isLoading;
    }, [studyTestForm, isLoading]);
    const testOptions = useMemo(() => tests.map((test) => ({
        label: translate(`${test.metadata.name}`),
        value: test.id.toString(),
        meta: {
            version: test.metadata.packages.find((pkg) => pkg.name === currentStudy.name)?.version,
        },
    })), [tests]);
    const saveTest = async () => {
        if (!saveDisabled) {
            try {
                setIsLoading(true);
                const { sandbox } = studyTestForm;
                executeTestPlan(testPlanId, parseInt(sandbox.value, 10), sandbox.label, sandbox.meta?.version);
                onSave();
            }
            catch (error) {
                console.error('Error executing study configuration', error);
                onDeployError();
            }
        }
    };
    const onDeployError = () => {
        addNotification({ title: translate('Error executing study'), type: 'error' });
        setIsLoading(false);
    };
    const savePrimaryButton = {
        label: translate('Execute'),
        onClick: saveTest,
        disabled: saveDisabled,
    };
    const cancelSecondaryButton = {
        label: translate('Cancel'),
        onClick: onClose,
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            saveTest();
        }
    };
    const onSubmit = (event) => {
        event.preventDefault();
        saveTest();
    };
    const handleTestChange = (selectedTest) => {
        setStudyTestForm((oldForm) => ({ ...oldForm, sandbox: selectedTest }));
    };
    const formSections = [
        {
            title: translate('Sandbox'),
            content: (_jsx("div", { className: 'flex flex-col gap-6 w-[27.5rem]', children: _jsx(Select, { dataTestId: 'select-sandbox', label: translate('Select sandbox'), description: translate('Select the sandbox to execute this test plan'), onChange: handleTestChange, options: testOptions, required: true, value: studyTestForm.sandbox, width: 'lg' }) })),
        },
    ];
    return (_jsxs(ModalWindow, { title: translate('Execute test plan'), isOpen: true, closeWindow: onClose, footerPrimaryActionButton: savePrimaryButton, footerSecondaryActionButtons: [cancelSecondaryButton], width: 'full', children: [isLoading && _jsx(Spinner, { wrapper: 'full' }), _jsx("form", { className: 'min-h-[30rem]', autoComplete: 'off', onSubmit: onSubmit, onKeyUp: handleEnterPress, children: _jsx(FormSections, { sections: formSections }) })] }));
};
