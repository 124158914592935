import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { EM_DASH } from 'libs.constants';
import { Dropdown } from 'libs.nucleus.dropdown';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Link } from 'libs.nucleus.link';
import { Spinner } from 'libs.nucleus.spinner';
import { Table } from 'libs.nucleus.table';
import { Tooltip } from 'libs.nucleus.tooltip';
import { AuthContext } from 'libs.react.auth';
import { FormattedDateTime, ListInformation, PageHeader, } from 'libs.react.components';
import { useApiClient } from 'libs.react.contexts';
import { useBooleanState, useToastNotification } from 'libs.react.hooks';
import { LibraryEndpoint } from 'libs.react.types';
import { ExecuteTestPlanModal } from './study_execute_test_plan_modal.component';
import { StudyTestPlanExecutionStatus } from './study_test_plan_execution_status.component';
import { TestPlanEditModal } from './test_plan_edit_modal.component';
import { StudyConfigurationContext, StudyTestPlanContext } from '../../contexts';
import { DeploymentUtils } from '../../utils/deployment';
export const StudyTestPlanDetails = ({ testPlanId, originPage }) => {
    const { addNotification } = useToastNotification();
    const translate = useLocalizeMessage();
    const navigate = useNavigate();
    const { studyId } = useContext(StudyConfigurationContext);
    const { entityId } = useContext(AuthContext);
    const { updateTestPlan, getTestPlanHistory, executeTestPlan, fetchReportUrl, deleteExecutionHistory, testPlanHistory, fetchTestPlan, } = useContext(StudyTestPlanContext);
    const momClient = useApiClient("mom" /* ApiClientService.MOM */);
    const libClient = useApiClient("library" /* ApiClientService.LIBRARY */);
    const [testPlan, setTestPlan] = useState(undefined);
    const [isEditTestPlanModalOpen, openEditTestPlanModal, closeEditTestPlanModal] = useBooleanState(false);
    const [isExecuteTestPlanModalOpen, openExecuteTestPlanModal, closeExecuteTestPlanModal] = useBooleanState(false);
    const retrieveTests = async () => {
        const endpoint = `/v1/entities/${entityId}/studies/${studyId}/environments?withWorkflows=true&clusterTypes=dev,ephemeral,sandbox`;
        const { data: response } = await momClient.get(endpoint);
        // We sort the tests by updated date to show newer first
        return response.data
            .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
            .filter((test) => {
            const status = DeploymentUtils.getStatus(test);
            return status === 'completed';
        });
    };
    const { data: studyTests = [], isLoading } = useQuery({
        queryKey: ['entities', entityId, 'studies', studyId, 'environments', 'completed'],
        queryFn: retrieveTests,
        enabled: !!entityId,
    });
    const testPlanHistoryList = useMemo(() => {
        if (!testPlanId) {
            return [];
        }
        return testPlanHistory[testPlanId] || [];
    }, [testPlanId, testPlanHistory]);
    const breadcrumbsPages = [
        originPage,
        {
            name: testPlan?.name || '',
            path: '',
        },
    ];
    const listItems = useMemo(() => {
        if (!testPlan) {
            return [];
        }
        return [
            { title: translate('Name'), description: testPlan.metadata.name },
            { title: translate('Description'), description: testPlan.data.description },
            {
                title: translate('Sandbox'),
                description: testPlan.metadata.sandboxName,
            },
            {
                title: translate('Date created'),
                description: _jsx(FormattedDateTime, { date: testPlan.createdAt }),
            },
            {
                title: translate('Last updated'),
                description: _jsx(FormattedDateTime, { date: testPlan.updatedAt }),
            },
        ];
    }, [testPlan]);
    const showEditTestModal = () => {
        openEditTestPlanModal();
    };
    const onUpdatePlan = async (updatedPlan) => {
        try {
            const { data: response } = await libClient.put(`${LibraryEndpoint.GET_TEST_PLANS}/${testPlan.id}`, {
                data: {
                    ...testPlan.data,
                    csv: updatedPlan,
                },
            });
            updateTestPlan({ ...response.data });
        }
        catch (error) {
            console.error(error);
            addNotification({
                title: translate('Error'),
                subtitle: translate('An error occurred while updating the test plan'),
                type: 'error',
            });
        }
    };
    const headerOptions = [
        { name: translate('View test plan'), onClick: showEditTestModal, variant: 'secondary' },
        {
            name: translate('Execute'),
            onClick: openExecuteTestPlanModal,
            variant: 'primary',
        },
    ];
    const executionHistoryColumns = useMemo(() => {
        return [
            {
                header: translate('Status'),
                cell: ({ row }) => _jsx(StudyTestPlanExecutionStatus, { testPlanExecutionHistory: row.original }),
            },
            {
                header: translate('Build version'),
                cell: ({ row }) => {
                    return (_jsx(Tooltip, { title: row.original.metadata.sandboxName, children: row.original.metadata.sandboxVersion || EM_DASH }));
                },
            },
            {
                header: translate('Executed on'),
                cell: ({ row }) => {
                    return _jsx(FormattedDateTime, { date: row.original.createdAt });
                },
            },
            {
                header: translate('Execution time'),
                cell: ({ row }) => {
                    if (row.original.status === 'RUNNING') {
                        return EM_DASH;
                    }
                    const startedAt = new Date(row.original.createdAt);
                    const endedAt = new Date(row.original.updatedAt);
                    const duration = endedAt.getTime() - startedAt.getTime();
                    const seconds = Math.floor(duration / 1000);
                    const minutes = Math.floor(seconds / 60);
                    const hours = Math.floor(minutes / 60);
                    if (hours > 0) {
                        return `${hours}h ${minutes % 60}m`;
                    }
                    else if (minutes > 0) {
                        return `${minutes}m ${seconds % 60}s`;
                    }
                    return `${seconds}s`;
                },
            },
            {
                header: translate('Report'),
                cell: ({ row }) => {
                    if (row.original.blobPath) {
                        return (_jsx(Link, { LinkComponent: RouterLink, to: row.original.blobPath, target: '_blank', onClick: async (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                const url = await fetchReportUrl(row.original.id);
                                window.open(url, '_blank');
                            }, children: translate('View report') }));
                    }
                    return EM_DASH;
                },
            },
            {
                header: '',
                id: 'options',
                cell: ({ row }) => {
                    const dropdownOptions = [];
                    if (row.original.status === 'RUNNING') {
                        return null;
                    }
                    if (testPlan) {
                        dropdownOptions.push({
                            id: 're-run',
                            content: translate('Re-Run'),
                            onClick: () => {
                                executeTestPlan(testPlan.id, row.original.metadata.sandboxId, row.original.metadata.sandboxName);
                            },
                        });
                    }
                    dropdownOptions.push({
                        id: 'delete',
                        content: translate('Delete'),
                        onClick: () => {
                            deleteExecutionHistory(row.original.id);
                        },
                        destructive: true,
                    });
                    return (_jsx("section", { className: 'flex flex-col items-end', children: _jsx(Dropdown, { position: 'bottom-end', items: dropdownOptions, variant: 'kebab' }) }));
                },
            },
        ];
    }, []);
    const retrieveTestPlan = async (planId) => {
        const newTestPlan = await fetchTestPlan(planId);
        if (newTestPlan) {
            setTestPlan(newTestPlan);
        }
        else {
            navigate(`/studies/${studyId}/test-plans`);
        }
    };
    useEffect(() => {
        if (testPlanId) {
            retrieveTestPlan(testPlanId);
            getTestPlanHistory(testPlanId);
        }
    }, [testPlanId]);
    return (_jsxs("section", { className: 'relative p-6 h-full flex flex-col', children: [_jsx(PageHeader, { breadcrumbPages: breadcrumbsPages, title: testPlan?.name || '', options: headerOptions }), _jsxs("div", { className: 'flex-1 overflow-auto relative', children: [isLoading && _jsx(Spinner, { wrapper: 'full' }), _jsx(ListInformation, { items: listItems }), _jsxs("div", { className: 'mt-8', children: [_jsx("h2", { className: 'text-lg font-semibold text-gray-800 mb-3', children: translate('Execution history') }), _jsx(Table, { columns: executionHistoryColumns, data: testPlanHistoryList, hasPagination: true, initialPageSize: 10, isLoading: isLoading, labelSearchField: translate('Search'), paginationNavigationScreenReaderLabel: translate('Test plan table navigation') })] }), isEditTestPlanModalOpen && testPlan?.data.csv ? (_jsx(TestPlanEditModal, { testPlan: testPlan?.data.csv, onClose: closeEditTestPlanModal, onUpdatePlan: onUpdatePlan })) : null, isExecuteTestPlanModalOpen && testPlan ? (_jsx(ExecuteTestPlanModal, { testPlanId: testPlan.id, onClose: closeExecuteTestPlanModal, onSave: closeExecuteTestPlanModal, tests: studyTests })) : null] })] }));
};
