import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { ProductTemplate } from '@axon/rosetta-sdk';
import { FirebaseAppContext } from 'libs.firebase_react';
import { FlagContext } from 'libs.flags.react';
import { FormSections } from 'libs.nucleus.form_sections';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Input } from 'libs.nucleus.input';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { Spinner } from 'libs.nucleus.spinner';
import { TextArea } from 'libs.nucleus.text_area';
import { AuthContext } from 'libs.react.auth';
import { useApiClient } from 'libs.react.contexts';
import { useToastNotification } from 'libs.react.hooks';
import { ErrorUtils } from 'libs.react.utils';
import { STUDY_MANAGEMENT_FLAGS } from '../../constants';
import { StudyConfigurationContext } from '../../contexts';
import { useJobChecker } from '../../hooks';
const DEFAULT_VERSION_FORM = {
    name: '',
    description: '',
};
export const CreateVersionModal = ({ isFirstVersion, onClose, onCreate }) => {
    const translate = useLocalizeMessage();
    const { checkJobStatus } = useJobChecker();
    const momClient = useApiClient("mom" /* ApiClientService.MOM */);
    const { addNotification } = useToastNotification();
    const { assignStudy, currentStudy, studyId, configId } = useContext(StudyConfigurationContext);
    const { entityId } = useContext(AuthContext);
    const flagManager = useContext(FlagContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isPristine, setIsPristine] = useState(true);
    const [versionForm, setVersionForm] = useState(DEFAULT_VERSION_FORM);
    const { logEvent } = useContext(FirebaseAppContext);
    const createEnabled = !isPristine && !!versionForm.name;
    const experimentalPackages = flagManager.getValue(STUDY_MANAGEMENT_FLAGS.experimentalPackages);
    const createVersion = async () => {
        if (createEnabled && !isLoading) {
            updateTemplate();
            setIsLoading(true);
            try {
                const { data: response } = await momClient.post(`/v1/entities/${entityId}/studies/${studyId}/publish`, {
                    spec: currentStudy.toRosetta(),
                    studyConfigId: configId,
                    metadata: {
                        description: versionForm.description,
                        name: versionForm.name,
                    },
                }, { no404Handling: true });
                const statusResponse = await checkJobStatus(response.data.jobId);
                if (statusResponse.status === 'completed') {
                    addNotification({
                        title: translate('Version created'),
                        subtitle: translate(`A new version with the latest build configuration has been created.`),
                        type: 'success',
                    });
                    onCreate();
                    logEvent('version_created', {
                        study_id: studyId,
                        version_name: versionForm.name,
                        version_description: versionForm.description,
                    });
                }
                else {
                    showErrorOnCreation(ErrorUtils.getResultErrorMessage(statusResponse.result));
                    logEvent('version_creation_failed', {
                        study_id: studyId,
                        version_name: versionForm.name,
                        version_description: versionForm.description,
                    });
                }
            }
            catch (error) {
                // @ts-expect-error - AxiosError type is not being caught by instanceof
                if (error.name === 'AxiosError' && !error.response?.data?.errorHandled) {
                    showErrorOnCreation(error.response?.data?.error);
                }
                logEvent('version_creation_failed', {
                    study_id: studyId,
                    version_name: versionForm.name,
                    version_description: versionForm.description,
                });
            }
            finally {
                setIsLoading(false);
            }
        }
    };
    /**
     * When creating the first version of a study, we need to update the template to the latest version
     * this is to ensure that the study is up to date with the latest packages and versions available
     * These versions will not change after again
     */
    const updateTemplate = () => {
        if (isFirstVersion) {
            currentStudy.resetPinnedProductTemplate();
            currentStudy.resetProductTemplate();
            currentStudy.resetProductPackages();
            currentStudy.resetProductVersions();
            if (experimentalPackages) {
                currentStudy.productTemplate = ProductTemplate.DEV_LATEST;
            }
            assignStudy(currentStudy);
        }
    };
    const showErrorOnCreation = (message) => {
        const subtitle = message
            ? translate('The version of your study build could not be saved because of "{message}"', { message })
            : translate('The version of your study build could not be saved. Please try again.');
        addNotification({ title: translate('Error creating study version'), subtitle, type: 'error' });
    };
    const savePrimaryButton = {
        label: translate('Create'),
        onClick: createVersion,
        disabled: !createEnabled,
    };
    const cancelSecondaryButton = {
        label: translate('Cancel'),
        onClick: onClose,
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            createVersion();
        }
    };
    const onSubmit = (event) => {
        event.preventDefault();
        if (createEnabled) {
            createVersion();
        }
    };
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setVersionForm((oldVersionForm) => ({ ...oldVersionForm, [id]: value }));
        setIsPristine(false);
    };
    const formSections = [
        {
            title: translate('Details'),
            content: (_jsxs("div", { className: 'flex flex-col gap-6 max-w-input-lg', children: [_jsx(Input, { id: 'name', label: translate('Name'), maxLength: 50, onChange: handleInputChange, required: true, value: versionForm.name, width: 'full', containLabels: true }), _jsx(TextArea, { id: 'description', label: translate('Description'), onChange: handleInputChange, value: versionForm.description, width: 'full' })] })),
        },
    ];
    return (_jsxs(ModalWindow, { title: translate('Create version'), isOpen: true, closeWindow: onClose, footerPrimaryActionButton: savePrimaryButton, footerSecondaryActionButtons: [cancelSecondaryButton], width: 'full', children: [isLoading && _jsx(Spinner, { wrapper: 'full' }), _jsx("form", { className: 'min-h-[30rem]', autoComplete: 'off', onSubmit: onSubmit, onKeyUp: handleEnterPress, children: _jsx(FormSections, { sections: formSections }) })] }));
};
