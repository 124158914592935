import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { getLocaleMetaInfo } from 'libs.i18n-core';
import { Button } from 'libs.nucleus.button';
import { Locale, useLocalizeMessage } from 'libs.nucleus.i18n';
import { Link } from 'libs.nucleus.link';
import { Spinner } from 'libs.nucleus.spinner';
import { Table } from 'libs.nucleus.table';
import { FormattedDateTime } from 'libs.react.components';
import { useAssetContext } from '../../hooks/use_asset_context.hook';
import { AssetVersion } from '../asset_version';
import { BuildVersion } from '../locale_matrix';
export const TableExport = ({ onExportButtonClick, data, isExporting, reviewPath, asset, status, }) => {
    const translate = useLocalizeMessage();
    const { isLibrary } = useAssetContext();
    const currentStatus = status || data.status;
    const columns = useMemo(() => {
        const columnData = [
            {
                header: translate('Base locale'),
                accessorKey: 'locale',
                cell: (value) => (_jsx(Link, { LinkComponent: RouterLink, to: reviewPath, children: value.getValue() })),
            },
            {
                header: translate('Language/script'),
                accessorKey: 'locale',
                cell: (value) => {
                    const { language } = getLocaleMetaInfo({ appLocale: Locale.en_US, targetLocale: value.getValue() });
                    return language;
                },
            },
            {
                header: translate('Country/Region'),
                accessorKey: 'locale',
                cell: (value) => {
                    const { region } = getLocaleMetaInfo({ appLocale: Locale.en_US, targetLocale: value.getValue() });
                    return region;
                },
            },
            ...('buildVersion' in asset
                ? [
                    {
                        accessorKey: 'buildVersion',
                        header: () => {
                            return _jsx("div", { className: 'text-center', children: translate('Build version') });
                        },
                        size: 200,
                        cell: () => _jsx(BuildVersion, { version: asset?.buildVersion }),
                    },
                ]
                : []),
            {
                accessorKey: 'status',
                header: () => {
                    return _jsx("div", { className: 'text-center', children: translate('Locale version') });
                },
                maxSize: 100,
                enableResizing: true,
                cell: ({ row }) => (_jsx(AssetVersion, { currentVersion: row.original.currentVersionStatus
                        ? row.original.currentVersionStatus
                        : {
                            version: Number(asset.version),
                            status: currentStatus,
                        } })),
            },
            {
                header: translate('Last exported'),
                size: 200,
                cell: ({ row }) => (_jsx("div", { className: 'min-w-32', children: _jsx(FormattedDateTime, { date: row.original.last_exported }) })),
            },
        ];
        if (isLibrary) {
            columnData.push({
                header: ' ',
                cell: () => (_jsx("div", { className: 'text-right px-6', children: _jsx(Button, { onClick: onExportButtonClick, label: translate('Export'), variant: 'transparent' }) })),
            });
        }
        // else {
        //   columnData.push({
        //     header: translate('Status'),
        //     cell: () =>
        //       currentStatus ? (
        //         <LocaleStatusIcon status={(status || data.status) as LocalizationStatus} />
        //       ) : (
        //         <Spinner size='sm' />
        //       ),
        //   });
        // }
        return columnData;
    }, [data]);
    return (_jsx("div", { className: 'flex flex-col gap-4 items-end', "data-testid": 'table-translations-export', children: _jsxs("div", { className: 'w-full', children: [isExporting && _jsx(Spinner, { wrapper: 'full' }), _jsx(Table, { data: [data], columns: columns })] }) }));
};
