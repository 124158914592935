import { jsx as _jsx } from "react/jsx-runtime";
import { EM_DASH } from 'libs.constants';
import { Pill } from 'libs.nucleus.pill';
export const BuildVersion = ({ version }) => {
    if (!version) {
        return EM_DASH;
    }
    const displayVersion = version?.toString().split('.')[0]; // Show only Major version
    return _jsx(Pill, { label: `v${displayVersion}`, backgroundColor: 'gray' });
};
