import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useMatch } from 'react-router-dom';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Icon } from 'libs.nucleus.icon';
import { InlineNotification } from 'libs.nucleus.notification';
import { Spinner } from 'libs.nucleus.spinner';
import { SiteContext } from '../../contexts/site';
import { getApiInstance } from '../../helpers/api.helpers';
import { isOutsideVisitWindowRange } from '../../helpers/visit.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { ActivitiesCard } from '../card';
import { SiteIcon } from '../svgIcons/site.icon';
import { UserIcon } from '../svgIcons/user.icon';
export const SiteTasksList = ({ tasks, visit, setVisitActivity, setIsActivityModalOpen, }) => {
    const translate = useLocalizeMessage();
    if (tasks.length === 0) {
        return (_jsx("section", { className: 'flex items-center p-4', children: _jsx("span", { className: 'px-2', children: translate('No site tasks available') }) }));
    }
    return tasks.map((task) => {
        return (_jsxs("section", { className: 'flex items-center p-4 cursor-pointer', "data-testid": `site-task-${task.c_group_task.c_assignment._id}`, onClick: () => {
                setVisitActivity({
                    visit,
                    activity: task,
                });
                setIsActivityModalOpen();
            }, children: [_jsx(SiteIcon, { className: 'flex-1 text-green-500' }), _jsx("span", { className: 'px-2', children: task.c_group_task.c_assignment.c_name })] }, task.c_group_task.c_assignment._id));
    });
};
export const ParticipantTasksList = ({ tasks }) => {
    const translate = useLocalizeMessage();
    if (tasks.length === 0) {
        return (_jsx("section", { className: 'flex items-center p-4', children: _jsx("span", { className: 'px-2', children: translate('No participant tasks available') }) }));
    }
    return tasks.map((task) => {
        return (_jsxs("section", { className: 'flex items-center p-4 bg-neutral-200', "data-testid": `participant-task-${task.c_task._id}`, children: [_jsx(UserIcon, { className: 'flex-1 nucleus-text-blue-500' }), _jsx("span", { className: 'px-2', children: task.c_task.c_name }), _jsxs("span", { className: 'ml-auto', children: [' ', _jsx(Icon, { color: 'black', name: 'lock', size: 'base' })] })] }, task.c_task._id));
    });
};
export const ActivitiesList = ({ visit, setVisitActivity, setIsActivityModalOpen }) => {
    const { environment } = useCurrentEnvironment();
    const { site } = useContext(SiteContext);
    const translate = useLocalizeMessage();
    const match = useMatch('/site/study/:studyId/participants/:participantId/*');
    const participantId = match?.params.participantId;
    const visitId = visit.c_visit;
    const getActivities = async () => {
        if (!environment || !visit.c_group) {
            return [{ siteTasks: [], participantTasks: [] }];
        }
        try {
            const responseSiteTasks = await getApiInstance(environment).get(`/v2/routes/c_sites/${site?._id}/c_public_users/${participantId}/c_groups/${visit.c_group}/c_task_assignments`);
            // TODO: Uncomment this block after the participant event permissions are fixed or find a different way to get the participant events
            // const responseParticipantTasks = await getApiInstance(environment).get(
            //   `/v2/routes/c_public_users/${participantId}/c_events`,
            //   {
            //     params: {
            //       paths: [
            //         'c_start',
            //         'c_end',
            //         'c_task.c_name',
            //         'c_task.c_type',
            //         'c_completed',
            //         'type',
            //         'c_missed',
            //         'c_task_assignment.c_schedule_rules',
            //         'c_auto_start',
            //         'c_canceled',
            //         'c_title',
            //         'c_timezone',
            //         'c_task_assignment.c_order',
            //         'c_order',
            //         'c_visit_id',
            //         'c_visit_name',
            //         'c_enforce_order',
            //         'c_task_assignment.c_visit',
            //         'c_modality',
            //       ],
            //     },
            //   }
            // );
            return [
                {
                    siteTasks: responseSiteTasks?.data.data,
                    participantTasks: [],
                },
            ];
        }
        catch (error) {
            console.error('Error getting participant activities', error);
            return [{ siteTasks: [], participantTasks: [] }];
        }
    };
    const { data: participantActivities, isLoading } = useQuery({
        queryKey: ['participantActivities', participantId, visitId],
        queryFn: getActivities,
        enabled: true,
    });
    if (!participantActivities || isLoading) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    return (_jsxs("div", { className: 'bg-neutral-100 p-4 h-full', children: [isOutsideVisitWindowRange(new Date(), visit) && (_jsx("section", { className: 'flex items-center pb-4', children: _jsx(InlineNotification, { subtitle: translate('This visit is outside the visit window'), title: '', type: 'warning' }) })), _jsxs(ActivitiesCard, { children: [_jsx(SiteTasksList, { tasks: participantActivities[0].siteTasks, visit: visit, setVisitActivity: setVisitActivity, setIsActivityModalOpen: setIsActivityModalOpen }), _jsx(ParticipantTasksList, { tasks: participantActivities[0].participantTasks })] })] }));
};
