import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useContext, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FirebaseAppContext } from 'libs.firebase_react';
import { FormSections } from 'libs.nucleus.form_sections';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Input } from 'libs.nucleus.input';
import { useDialog } from 'libs.nucleus.modal_dialog';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { RadioGroup, RadioGroupOption } from 'libs.nucleus.radio_group';
import { Select } from 'libs.nucleus.select';
import { useToastNotification } from 'libs.react.hooks';
import { StudiesContext, StudyConfigurationContext } from '../../../../contexts';
import { GenericUtils, hasScheduleTimelineCondition } from '../../../../utils';
export const ScheduleTimelineModal = ({ initialSchedule, onCancel, onDeleteTimeline, onSave, }) => {
    const translate = useLocalizeMessage();
    const dialog = useDialog();
    const toast = useToastNotification();
    const { changeCurrentTimelineId, configId, currentStudy, currentTimelineId, studyId } = useContext(StudyConfigurationContext);
    const { updateStudy } = useContext(StudiesContext);
    const { logEvent } = useContext(FirebaseAppContext);
    const [isPristine, setIsPristine] = useState(true);
    const [schedule, setSchedule] = useState(initialSchedule || { id: uuidv4(), name: '' });
    const [isCloning, setIsCloning] = useState(false);
    const [scheduleToClone, setScheduleToClone] = useState({ value: '', label: '-select-' });
    const allTimelines = Object.values(currentStudy.getScheduleTimelines());
    const hasSameName = allTimelines.some((timeline) => initialSchedule?.id !== timeline.id && GenericUtils.isSameString(timeline.name, schedule.name));
    const isSaveDisabled = isPristine || !schedule.name || hasSameName || (isCloning && !scheduleToClone.value);
    const deleteTimeline = async () => {
        if (initialSchedule) {
            const hasDependentTimelineCondition = hasScheduleTimelineCondition(initialSchedule.id, currentStudy);
            const ok = await dialog.open({
                title: translate('Delete schedule'),
                body: !hasDependentTimelineCondition
                    ? translate('Are you sure you want to delete this schedule? This action cannot be undone.')
                    : translate('You are about to delete a schedule timeline that a schedule assignment is dependent on. Please check your configured scheduled assignments before proceeding with deleting this timeline. Doing so will remove those dependencies.'),
                actionButtons: [{ isDestructive: true, label: translate('Delete') }, { label: translate('Cancel') }],
            });
            if (ok) {
                changeCurrentTimelineId(currentStudy.timelineInfo.main.id);
                currentStudy.deleteTimeline(initialSchedule.id);
                // we update the study directly as changing the timeline id will already handle the internal context update
                await updateStudy(configId, currentStudy);
                toast.addNotification({
                    type: 'success',
                    title: translate('{schedule} deleted successfully', { schedule: initialSchedule.name }),
                });
                onDeleteTimeline();
                logEvent('schedule_timeline_deleted', {
                    study_id: studyId,
                    timeline_id: initialSchedule.id,
                    timeline_name: initialSchedule.name,
                });
            }
        }
    };
    const saveButton = {
        label: initialSchedule ? translate('Save') : translate('Create'),
        onClick: () => onSave({ schedule, isCloning, scheduleToClone }),
        disabled: isSaveDisabled,
    };
    const cancelButton = {
        label: translate('Cancel'),
        onClick: onCancel,
    };
    const deleteButton = {
        label: translate('Delete'),
        onClick: deleteTimeline,
        variant: 'destructive',
    };
    const onSubmit = (event) => {
        event.preventDefault();
        if (!isSaveDisabled) {
            onSave({ schedule, isCloning, scheduleToClone });
        }
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter' && !isSaveDisabled) {
            onSave({ schedule, isCloning, scheduleToClone });
        }
    };
    const handleNameChange = (ev) => {
        setIsPristine(false);
        setSchedule({ ...schedule, name: ev.target.value });
    };
    const handleChangeIsCloning = (value) => {
        setIsCloning(value);
        setIsPristine(false);
    };
    const handleScheduleToCloneChange = (option) => {
        setIsPristine(false);
        setScheduleToClone(option);
    };
    const scheduleOptions = useMemo(() => {
        if (initialSchedule) {
            return [];
        }
        const options = Object.values(currentStudy.timelineInfo)
            .filter(({ hidden }) => !hidden)
            .map(({ id, name }) => ({ value: id, label: name }));
        const initialOption = options.find(({ value }) => value === currentTimelineId);
        if (initialOption) {
            setScheduleToClone(initialOption);
        }
        return [{ value: '', label: translate('-select-') }, ...options];
    }, []);
    const sections = [
        {
            title: translate('Details'),
            content: (_jsxs("div", { className: 'flex flex-col gap-6 max-w-input-lg', children: [_jsx(Input, { id: 'schedule-timeline-name', width: 'full', label: translate('Schedule name'), hasError: hasSameName, errorMessage: translate('A schedule with this name already exists. Please choose a different name.'), value: schedule.name, onChange: handleNameChange, containLabels: true }), !initialSchedule && (_jsxs(RadioGroup, { value: isCloning, onChange: handleChangeIsCloning, children: [_jsx(RadioGroupOption, { option: { value: false, label: translate('New schedule') } }), _jsx(RadioGroupOption, { option: { value: true, label: translate('Clone existing schedule') } })] })), !initialSchedule && isCloning && (_jsx("div", { className: 'ml-6', children: _jsx(Select, { label: translate('Select schedule to clone'), options: scheduleOptions, value: scheduleToClone, onChange: handleScheduleToCloneChange, width: 'full' }) }))] })),
        },
    ];
    return (_jsx(ModalWindow, { title: initialSchedule ? translate('Edit schedule') : translate('Create schedule'), subtitle: initialSchedule?.name, isOpen: true, width: 'full', footerPrimaryActionButton: saveButton, footerSecondaryActionButtons: initialSchedule && currentTimelineId !== currentStudy.timelineInfo.main.id
            ? [cancelButton, deleteButton]
            : [cancelButton], closeWindow: onCancel, children: _jsx("form", { className: 'flex min-h-[16rem] flex-col gap-4', autoComplete: 'off', onSubmit: onSubmit, onKeyUp: handleEnterPress, children: _jsx(FormSections, { sections: sections }) }) }));
};
