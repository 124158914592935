import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { EM_DASH } from 'libs.constants';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { InlineNotification } from 'libs.nucleus.notification';
import { Spinner } from 'libs.nucleus.spinner';
import { useBooleanState, useToastNotification } from 'libs.react.hooks';
import { sendParticipantIniteEmail } from './helpers/participant_form.helpers';
import { getApiErrorMessage } from '../../helpers/api.helpers';
import { getStudyShortCode } from '../../helpers/study.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { useOrgContext } from '../../hooks/use_org_context';
import { useSiteContext } from '../../hooks/use_site_context';
import { UserCheckIcon } from '../svgIcons/user_check.icon';
export const ParticipantInviteModal = ({ isModalOpen, participant, onClose, onDone }) => {
    const translate = useLocalizeMessage();
    const org = useOrgContext();
    const { environment } = useCurrentEnvironment();
    const site = useSiteContext();
    const [isSendingEmail, setIsSendingEmail, unsetIsSendingEmail] = useBooleanState(false);
    const queryClient = useQueryClient();
    const { addNotification } = useToastNotification();
    const getStudyCode = async () => {
        return await getStudyShortCode(window.location.hostname, environment?.cortexURL ?? '', environment?.code ?? '');
    };
    const { data: studyCode } = useQuery({
        queryKey: ['getStudyCode', environment?.code],
        queryFn: getStudyCode,
        enabled: !queryClient.getQueryData(['getStudyCode', environment?.code]),
    });
    const emailCodesToParticipant = async () => {
        if (!environment) {
            throw new Error('Environment is not defined');
        }
        if (studyCode) {
            setIsSendingEmail();
            try {
                await sendParticipantIniteEmail({
                    environment,
                    org,
                    participant,
                    site,
                    studyInviteCode: studyCode,
                });
                addNotification({
                    title: '',
                    subtitle: translate('Invite has been sent'),
                    type: 'success',
                });
            }
            catch (e) {
                console.error('Error sending participant invite email', e);
                const message = getApiErrorMessage(e);
                addNotification({
                    title: '',
                    subtitle: message ?? translate('We were unable to process your request. Try again later.'),
                    type: 'error',
                });
            }
            finally {
                unsetIsSendingEmail();
            }
        }
    };
    const sendingEmailButton = (_jsxs("div", { className: 'flex items-center gap-3', children: [_jsx(Spinner, { size: 'sm' }), " ", translate('Sending email'), "..."] }));
    return (_jsx(ModalWindow, { title: translate('Participant Invite Information'), isOpen: isModalOpen, closeWindow: onClose, footerSecondaryActionButtons: [
            {
                label: translate('Email codes to participant'),
                onClick: emailCodesToParticipant,
                disabled: isSendingEmail || !participant?.c_email,
                content: isSendingEmail ? sendingEmailButton : undefined,
                id: 'email-invite-codes',
                dataTestId: 'email-invite-codes',
            },
            { label: translate('Cancel'), onClick: onClose },
        ], footerPrimaryActionButton: {
            label: translate('Done'),
            onClick: onDone ?? onClose,
            id: 'invite-modal-done',
            dataTestId: 'invite-modal-done',
        }, width: 'full', children: _jsxs("div", { className: 'flex flex-col h-full justify-between', children: [_jsxs("div", { className: 'flex flex-col flex-grow gap-6 items-center justify-center', children: [_jsx(UserCheckIcon, { className: 'flex-1 nucleus-text-primary-500' }), _jsxs("section", { className: 'text-center', children: [_jsx("h4", { className: 'nucleus-text-h4 nucleus-text-black font-extrabold', children: translate('Participant Invite Code') }), _jsx("p", { className: 'text-sm nucleus-text-neutral-500', children: translate('Please share this invite code with your participant to enable them to join from the Medable app.') })] }), _jsxs("section", { className: 'text-center', children: [_jsx("span", { children: translate('Study code') }), _jsx("div", { className: 'nucleus-px-6 py-1 bg-neutral-100', children: _jsx("span", { className: 'nucleus-text-h1 nucleus-font-light', children: studyCode ?? EM_DASH }) })] }), _jsxs("section", { className: 'text-center', children: [_jsx("span", { children: translate('Invite code') }), _jsx("div", { className: 'nucleus-px-6 py-1 bg-neutral-100', children: _jsx("span", { className: 'nucleus-text-h1 nucleus-font-light', children: participant?.c_access_code ?? EM_DASH }) })] })] }), !org?.c_no_pii && !participant?.c_email && (_jsx("div", { className: 'flex', children: _jsx(InlineNotification, { subtitle: translate('Please enter an email for the participant before sending invite codes'), title: translate('No Email'), type: 'warning' }) }))] }) }));
};
