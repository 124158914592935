import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AssetTypes } from 'libs.localization.common';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { InlineNotification } from 'libs.nucleus.notification';
import { PageHeader } from 'libs.react.components';
import { SectionExport } from '../../components/section_export';
import { SectionImport } from '../../components/section_import';
import { TranslationWorkbenchContext } from '../../contexts';
import { useLocaleAccess } from '../../hooks';
import { getExportTableStatus } from '../../utils';
import { mapStudyAssetToRole, getRolebyAvailableTo } from '../../utils/data_utils';
import { toSentenceCase } from '../../utils/string_utils';
export const AssetsView = ({ studyId, assetId }) => {
    const t = useLocalizeMessage();
    const [queryParams] = useSearchParams();
    const { studyLocalizationStatus } = useContext(TranslationWorkbenchContext);
    const assetType = queryParams.get('assetType');
    let pageTitle = toSentenceCase(assetType);
    let currentRole = mapStudyAssetToRole(assetType);
    const { locales, study, studyAssets, notificationsAssets } = useContext(TranslationWorkbenchContext);
    if (assetType === AssetTypes.RESOURCES) {
        const resource = study.studyResources.find((r) => r.id === assetId);
        pageTitle = toSentenceCase(resource?.title) || t('Resource');
        if (resource?.availableTo) {
            currentRole = getRolebyAvailableTo(resource.availableTo);
        }
    }
    else if ([AssetTypes.NOTIFICATION_PUSH_NOTIFICATION, AssetTypes.NOTIFICATION_EMAIL, AssetTypes.NOTIFICATION_SMS].includes(assetType)) {
        // @TODO: Revisit this
        const notification = Object.keys(notificationsAssets).find((value) => notificationsAssets[value].id === assetId);
        pageTitle = toSentenceCase(notification) || t('Notification');
    }
    else {
        pageTitle =
            Object.keys(studyAssets).find((value) => studyAssets[value].assetType === assetType) || toSentenceCase(assetType);
    }
    const { checkIsValidLocale } = useLocaleAccess(currentRole);
    const filteredLocales = locales.filter(checkIsValidLocale);
    const breadcrumbPages = useMemo(() => [
        { name: t('Translations'), path: `/studies/${studyId}/build/translations` },
        { name: pageTitle, path: `/studies/${studyId}/build/translations/${assetId}` },
    ], [assetId, assetType]);
    if (assetId === undefined || assetType === undefined) {
        return _jsx(InlineNotification, { title: t('Missing asset information') });
    }
    const asset = {
        type: assetType,
        id: assetId,
        version: '1', // @TODO figure this out
    };
    return (_jsxs(_Fragment, { children: [_jsx(PageHeader, { title: pageTitle, breadcrumbPages: breadcrumbPages }), _jsx("hr", { className: 'mb-6' }), _jsxs("div", { className: 'flex flex-col gap-6', children: [_jsx(SectionExport, { asset: asset, trackingContext: { context: 'study' }, status: getExportTableStatus(studyLocalizationStatus) }), _jsx("hr", {}), _jsx(SectionImport, { asset: asset, locales: filteredLocales, trackingContext: { view: 'study', studyId, asset: pageTitle } })] })] }));
};
