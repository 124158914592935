import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFoundPage } from 'libs.react.components';
import { SitesWrapper } from './components/sites_wrapper';
import { AxonAccountProvider } from './contexts/axon_account/axon_account.context';
import { EnvironmentsProvider } from './contexts/environments/environments.context';
import { OrgProvider } from './contexts/org/org.context';
import { SiteProvider } from './contexts/site/site.context';
import { ParticipantDetailsPage } from './pages/participant_details/participant_details.page';
import { ParticipantsList } from './pages/participants/participants_list.page';
import { VisitDetailsPage } from './pages/visit_details/visit_details.page';
// import {
//   ChatContextProvider,
//   DeploymentProvider,
//   StudyConfigurationProvider,
//   StudiesProvider,
//   VariablesProvider,
// } from './contexts-back';
const StudiesDashboardPage = lazy(() => import('./pages/studies_dashboard/studies_dashboard.page'));
// const SitesHome = lazy(() => import('./pages/sites_home/sites_home.page'));
const ProvidersWrapper = ({ children }) => {
    return (_jsx(EnvironmentsProvider, { children: _jsx(AxonAccountProvider, { children: _jsx(OrgProvider, { children: _jsx(SiteProvider, { children: children }) }) }) }));
};
export const SiteApp = () => {
    return (_jsx(ProvidersWrapper, { children: _jsxs(Routes, { children: [_jsx(Route, { index: true, path: 'dashboard', element: _jsx(StudiesDashboardPage, {}) }), _jsxs(Route, { path: '/study/:studyId', element: _jsx(SitesWrapper, {}), children: [_jsx(Route, { index: true, path: 'participants', element: _jsx(ParticipantsList, {}) }), _jsx(Route, { path: 'participants/:participantId', element: _jsx(ParticipantDetailsPage, {}) }), _jsx(Route, { path: 'participants/:participantId/visit/:visitId', element: _jsx(VisitDetailsPage, {}) })] }), _jsx(Route, { path: '*', element: _jsx(NotFoundPage, {}) })] }) }));
};
export { SITE_FLAGS } from './constants/developer_flags.constants';
