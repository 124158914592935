import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Input } from 'libs.nucleus.input';
import { Select } from 'libs.nucleus.select';
import { TextArea } from 'libs.nucleus.text_area';
export const useTableInlineEdit = () => {
    const translate = useLocalizeMessage();
    const [table, setTable] = useState();
    const [isTableValid, setIsTableValid] = useState(true);
    const [tableErrors, setTableErrors] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const defaultSelectOption = useMemo(() => ({ label: translate('-select-'), value: '' }), []);
    const onRemoveRow = (row) => {
        const rowId = `${row.original.id}_`;
        setTableErrors((prev) => {
            const updatedErrors = Object.keys(prev).reduce((acc, key) => {
                if (!key.startsWith(rowId)) {
                    acc[key] = prev[key];
                }
                return acc;
            }, {});
            return updatedErrors;
        });
        table?.options?.meta?.updateData((prev) => {
            const updatedData = [...prev];
            updatedData.splice(row.index, 1);
            return updatedData;
        });
    };
    const onInsertNewRow = () => {
        table?.options?.meta?.updateData((prev) => {
            const updatedData = [...prev];
            updatedData.push({ id: uuidv4() });
            return updatedData;
        });
    };
    const onCloneRow = (index) => {
        table?.options?.meta?.updateData((prev) => {
            const updatedData = [...prev];
            updatedData.push({ ...updatedData[index], id: uuidv4() });
            return updatedData;
        });
    };
    const onCancel = (data) => {
        table?.options?.meta?.updateData(data);
        setIsEditMode(false);
    };
    const onFinish = (onFinishFn) => {
        try {
            // Remove empty rows
            const filteredTableState = table?.options?.meta?.data.filter((row) => Object.entries(row).some(([key, value]) => {
                if (key === 'id') {
                    return false;
                }
                return value !== undefined && value !== '';
            }));
            onFinishFn && onFinishFn(filteredTableState);
            setIsEditMode(false);
        }
        catch (e) {
            console.error(e);
        }
    };
    useEffect(() => {
        setIsTableValid(Object.keys(tableErrors).length === 0);
    }, [tableErrors]);
    useEffect(() => {
        setTableErrors({});
    }, [isEditMode]);
    const EditableField = (props) => {
        const { getValue, row, column: { id }, table, type = 'text', required = false, setTableErrors, dependencyFilterFn, dependencies, enabled = true, inputProps = {}, validator, onChange, } = props;
        let { selectOptions = [] } = props;
        const initialValue = getValue();
        const { index } = row;
        const [hasError, setHasError] = useState(!!(required && !initialValue));
        const isEditMode = table.options.meta?.isEditMode;
        const cellKey = `${row.original.id}_${id}`;
        const validate = (value) => {
            if (validator) {
                return validator(value);
            }
            return true;
        };
        useEffect(() => {
            if ((required && !initialValue) || (initialValue && !validate(initialValue))) {
                setTableErrors && setTableErrors((prev) => ({ ...prev, [`${cellKey}`]: true }));
                setHasError(true);
            }
            else {
                setTableErrors &&
                    setTableErrors((prev) => {
                        const rest = { ...prev };
                        delete rest[cellKey];
                        return rest;
                    });
                setHasError(false);
            }
        }, [table.options.meta?.data]);
        const onEdit = (value = initialValue) => {
            setHasError(false);
            if ((required && !value) || (value && !validate(value))) {
                setHasError(true);
            }
            onChange && onChange(value);
            table.options.meta?.updateColumnData(index, id, value);
            dependencies?.forEach((dependency) => {
                table.options.meta?.updateColumnData(index, dependency, '');
            });
        };
        if (isEditMode && dependencyFilterFn) {
            selectOptions = selectOptions.filter(dependencyFilterFn);
        }
        return (_jsxs(_Fragment, { children: [!isEditMode && (_jsxs("div", { children: [type === 'text' && _jsx("span", { children: initialValue }), type === 'textarea' && (_jsx("span", { children: typeof initialValue === 'object' ? JSON.stringify(initialValue, null, 2) : initialValue })), type === 'select' && (_jsx("span", { children: selectOptions.find((option) => option.value === initialValue)?.label ?? '' })), !initialValue && '-'] })), isEditMode && type === 'text' && (_jsx(Input, { id: `${uuidv4()}`, value: initialValue || '', width: 'sm', onChange: (e) => onEdit(e.target.value), required: required, hasError: hasError, disabled: !enabled, ...inputProps, containLabels: true })), isEditMode && type === 'textarea' && (_jsx(TextArea, { id: `${uuidv4()}`, label: '', value: typeof initialValue === 'object' ? JSON.stringify(initialValue, null, 2) : initialValue || '', onChange: (e) => onEdit(e.target.value), disabled: !enabled, ...inputProps })), isEditMode && type === 'select' && (_jsx(Select, { required: required, value: selectOptions.find((option) => option.value === initialValue) || defaultSelectOption, options: selectOptions, hasError: hasError, disabled: !enabled, onChange: (selectedOption) => {
                        onEdit(selectedOption.value);
                    }, ...inputProps }))] }));
    };
    const EditableTextField = (props) => (_jsx(EditableField, { ...props, type: 'text', setTableErrors: setTableErrors }));
    const EditableSelectField = (props) => (_jsx(EditableField, { ...props, type: 'select', setTableErrors: setTableErrors }));
    const EditableTextareaField = (props) => (_jsx(EditableField, { ...props, type: 'textarea', setTableErrors: setTableErrors }));
    return {
        isTableValid,
        isEditMode,
        setTable,
        onCancel,
        onFinish,
        EditableTextField,
        EditableSelectField,
        EditableTextareaField,
        onRemoveRow,
        onInsertNewRow,
        onCloneRow,
        setIsEditMode,
    };
};
