const matchEnvAndCloudFromHostname = (host) => {
    const hostname = host?.replace('https://', '');
    // Ensure all possible combinations of the environment and cloud domain are matched.
    const regexString = `^cloud([.-]?)(?<environment>[a-zA-Z0-9-.]+)?.medable.(?<cloudDomain>(com|cn|tech))`;
    const regex = new RegExp(regexString);
    return hostname.match(regex);
};
export const getMoaBaseUrl = (hostname) => {
    const matchResult = matchEnvAndCloudFromHostname(hostname);
    const { cloudDomain } = matchResult?.groups || {};
    if (['cloud.medable.com'].includes(hostname)) {
        return 'api.medable.com';
    }
    if (cloudDomain === 'cn') {
        return 'api.medable.cn';
    }
    return 'api-int-dev.medable.com';
};
export const getFinalUrl = (baseURL, encoded) => {
    return `https://${baseURL}/moa/routes/moa/gen/${encoded}`;
};
export const getStudyShortCode = async (hostname, cortexUrl, orgCode) => {
    const moaBaseUrl = getMoaBaseUrl(hostname);
    const cortexHostname = cortexUrl.replace('https://', '');
    const encoded = encodeURIComponent(`${cortexHostname}/${orgCode}`);
    const fetchResponse = await fetch(getFinalUrl(moaBaseUrl, encoded));
    const response = await fetchResponse.json();
    return response?.data;
};
