import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState, useContext, useMemo, } from 'react';
import { v4 } from 'uuid';
import { ActivityDependencyTriggerType, DefaultActivityId, DefaultTimelineId, SDKVisit, } from '@axon/rosetta-sdk';
import { FirebaseAppContext } from 'libs.firebase_react';
import { Checkbox } from 'libs.nucleus.checkbox';
import { FormSections } from 'libs.nucleus.form_sections';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Input } from 'libs.nucleus.input';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { RadioGroup, RadioGroupOption } from 'libs.nucleus.radio_group';
import { Select } from 'libs.nucleus.select';
import { Text } from 'libs.nucleus.typography';
import { ONLY_POSITIVE_INTEGERS_REGEX } from '../../../constants';
import { StudyConfigurationContext } from '../../../contexts';
import { useTimeframes } from '../../../hooks';
import { GenericUtils, getSelectOption } from '../../../utils';
const DEFAULT_VISIT_FORM = {
    code: '',
    daysFromStart: 0,
    isOptional: false,
    isTelevisitEnabled: false,
    minus: { value: '0', label: '--' },
    name: '',
    plus: { value: '0', label: '--' },
    sequentialActivityOrder: false,
    status: { value: '', label: '-select status-' },
    visitType: 'standard',
    maxOccurrences: 1,
};
const MAX_RECURRENCE_COUNT = 50;
const getNewEncounterDetails = (visitForm, initialVisit) => {
    const details = initialVisit?.details;
    return SDKVisit.createDetails({
        activityIds: details?.activityIds || [],
        code: visitForm.code,
        daysAfterRelative: visitForm.daysFromStart,
        duration: details?.duration || '',
        encounterAnchor: visitForm.name,
        endEncounterId: details?.endEncounterId || '',
        isOnboarding: details?.isOnboarding ?? false,
        isOptional: visitForm.isOptional,
        isScheduled: details?.isScheduled ?? true,
        offsetAfterWindow: Number(visitForm.plus?.value || 0),
        offsetBeforeWindow: Number(visitForm.minus?.value || 0),
        sequentialActivityOrder: visitForm.sequentialActivityOrder,
        televisitEnabled: visitForm.isTelevisitEnabled,
        visitConfirmation: details?.visitConfirmation || '',
        visitType: visitForm.visitType,
        maxOccurrences: visitForm.visitType === 'unscheduled' ? Number(visitForm.maxOccurrences) : undefined,
    });
};
const getStartAvailabilityCondition = ({ visit, currentTimelineId, startAvailabilityOption, prevConditionId, }) => {
    const visitConfirmationInstance = visit.getActivitySequenceByActivityId(DefaultActivityId.VISIT_CONFIRMATION);
    return {
        scheduleTimelineId: currentTimelineId,
        activityId: visitConfirmationInstance.activityId,
        activityInstanceId: visitConfirmationInstance.instanceId,
        dependencyId: startAvailabilityOption.meta?.activityId,
        dependencyInstanceId: startAvailabilityOption.value,
        dependencyType: 'start',
        id: prevConditionId || v4(),
        offsetAfterDateAnchor: 0,
        triggerType: ActivityDependencyTriggerType.ACTIVITY_COMPLETION,
        encounterId: visit.id,
    };
};
export const ScheduleTimelineVisitModal = ({ initialVisit, isCloning, onCancel, onSave, earlyWithdrawalExists, unscheduledVisitExists, followUpVisitExists, }) => {
    const { currentStudy, studyId, visits, currentTimelineId } = useContext(StudyConfigurationContext);
    const { logEvent } = useContext(FirebaseAppContext);
    const translate = useLocalizeMessage();
    const { dayOptions } = useTimeframes();
    const standardVisits = visits.filter((visit) => visit.visitType === 'standard');
    const isFirstVisit = !isCloning &&
        currentTimelineId !== DefaultTimelineId.ONBOARDING &&
        (standardVisits.length === 0 || initialVisit?.id === standardVisits[0]?.id);
    /**
     * List of participant statuses available to used as Dropdown Options
     */
    const statusOptions = useMemo(() => {
        const options = [{ label: translate('-select status-'), value: '' }];
        return options.concat(currentStudy.participantStatuses.map((status) => ({ label: status.value, value: status.id })));
    }, [currentStudy]);
    const startAvailabilityOptions = useMemo(() => {
        const onboardingVisit = Object.values(currentStudy.visits[DefaultTimelineId.ONBOARDING])[0];
        return Object.values(onboardingVisit.activities)
            .sort((activityA, activityB) => (activityA.order || 0) - (activityB.order || 0))
            .map((activity) => {
            const sequence = onboardingVisit.activitySequence.find((sequence) => sequence.activityId === activity.id);
            return {
                label: activity.name,
                value: sequence.instanceId,
                meta: { activityId: activity.id },
            };
        });
    }, []);
    const [visitForm, setVisitForm] = useState(() => {
        let initialVisitForm = DEFAULT_VISIT_FORM;
        if (initialVisit) {
            const initialStatus = currentStudy.participantStatusConditions.find((savedCondition) => savedCondition.encounterId === initialVisit.id);
            const minusOption = dayOptions.find((option) => option.value === String(initialVisit.offsetBeforeWindow));
            const plusOption = dayOptions.find((option) => option.value === String(initialVisit.offsetAfterWindow));
            const statusOption = statusOptions.find((option) => option.value === initialStatus?.updatesParticipantStatusTo);
            let startAvailability;
            if (isFirstVisit) {
                const visitConfirmationStartCondition = initialVisit.getActivitySequenceByActivityId(DefaultActivityId.VISIT_CONFIRMATION)?.timing.startConditions[0];
                startAvailability = visitConfirmationStartCondition
                    ? getSelectOption(startAvailabilityOptions, visitConfirmationStartCondition.dependencyInstanceId)
                    : startAvailabilityOptions[startAvailabilityOptions.length - 1];
            }
            initialVisitForm = {
                ...initialVisitForm,
                name: !isCloning ? initialVisit.name : '',
                visitType: initialVisit.visitType === 'supplemental' ? 'standard' : initialVisit.visitType,
                ...(!isCloning && { code: initialVisit.code }),
                daysFromStart: initialVisit.daysAfterRelative,
                isOptional: initialVisit.isOptional,
                isTelevisitEnabled: initialVisit.televisitEnabled,
                minus: minusOption || dayOptions[0],
                plus: plusOption || dayOptions[0],
                sequentialActivityOrder: initialVisit.sequentialActivityOrder || false,
                status: statusOption || statusOptions[0],
                maxOccurrences: initialVisit.maxOccurrences || 1,
                startAvailability,
            };
        }
        else if (isFirstVisit) {
            initialVisitForm = {
                ...initialVisitForm,
                startAvailability: startAvailabilityOptions[startAvailabilityOptions.length - 1],
            };
        }
        return initialVisitForm;
    });
    const [isPristine, setIsPristine] = useState(true);
    const nameInput = useRef(null);
    const hasSameName = visits.length > 0 &&
        visits.some((visit) => GenericUtils.isSameString(visit.name, visitForm.name) && (isCloning || initialVisit?.id !== visit.id));
    const isSaveEnabled = visitForm.name &&
        visitForm.code &&
        !isPristine &&
        !hasSameName &&
        (visitForm.visitType !== 'unscheduled' || (visitForm.maxOccurrences > 0 && visitForm.maxOccurrences <= 50));
    useEffect(() => {
        if (nameInput.current) {
            nameInput.current.focus();
        }
    }, []);
    const updateVisitForm = (partialVisitForm) => {
        setVisitForm((oldVisitForm) => ({ ...oldVisitForm, ...partialVisitForm }));
    };
    const handleInputChange = (event) => {
        updateVisitForm({ [event.target.id]: event.target.value });
        setIsPristine(false);
    };
    const handleDaysFromStartChange = (event) => {
        updateVisitForm({ daysFromStart: Number(event.target.value) });
        setIsPristine(false);
    };
    const handleIsOptionalChange = (newValue) => {
        updateVisitForm({ isOptional: newValue });
        setIsPristine(false);
    };
    const handleIsTelevisitEnabled = (newValue) => {
        updateVisitForm({ isTelevisitEnabled: newValue });
        setIsPristine(false);
    };
    const handleSelectChange = (id, option) => {
        updateVisitForm({ [id]: option });
        setIsPristine(false);
    };
    const handleActivityOrderChange = (sequentialActivityOrder) => {
        updateVisitForm({ sequentialActivityOrder });
        setIsPristine(false);
    };
    const handleVisitTypeChange = (visitType) => {
        updateVisitForm({ visitType });
        setIsPristine(false);
    };
    const onSubmit = (event) => {
        event.preventDefault();
        if (isSaveEnabled) {
            handleSave();
        }
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter' && isSaveEnabled) {
            handleSave();
        }
    };
    /**
     * It converts the different status conditions into the format required by the SDK
     * This SDK format has (for now) some fields that are not used in the UI and that will be removed soon
     */
    const generateParticipantStatusConditions = (visitId) => {
        // We filter out any condition belonging to this activity and timeline
        const filteredParticipantStatusConditions = currentStudy.participantStatusConditions.filter((savedCondition) => savedCondition.encounterId !== visitId);
        if (!visitForm.status.value) {
            return filteredParticipantStatusConditions;
        }
        const newStatusCondition = {
            activityId: DefaultActivityId.VISIT_CONFIRMATION,
            encounterId: visitId,
            id: v4(),
            scheduleTimelineId: currentTimelineId,
            triggerType: 'activity_completion',
            updatesParticipantStatusTo: visitForm.status.value,
        };
        return [...filteredParticipantStatusConditions, newStatusCondition];
    };
    const handleSave = () => {
        if (!initialVisit) {
            const visit = createVisit(currentTimelineId);
            currentStudy.addVisitConfirmationActivity(currentTimelineId, visit);
            currentStudy.participantStatusConditions = generateParticipantStatusConditions(visit.id);
            if (visitForm.visitType === 'standard' && visitForm.startAvailability) {
                visit.activitySequence[0].timing.startConditions = [
                    getStartAvailabilityCondition({
                        visit,
                        currentTimelineId,
                        startAvailabilityOption: visitForm.startAvailability,
                    }),
                ];
            }
            onSave(visit);
            logEvent('study_visit_added', { study_id: studyId, visit_name: visitForm.name });
        }
        else {
            if (isCloning) {
                const newVisit = initialVisit.cloneVisit({ name: visitForm.name });
                newVisit.details = getNewEncounterDetails(visitForm, newVisit);
                currentStudy.participantStatusConditions = generateParticipantStatusConditions(newVisit.id);
                onSave(newVisit);
                logEvent('study_visit_cloned', {
                    study_id: studyId,
                    visit_id: visitForm.name,
                    visit_cloned_from: initialVisit.name,
                });
            }
            else {
                if (isFirstVisit) {
                    const visitConfirmationInstance = initialVisit.getActivitySequenceByActivityId(DefaultActivityId.VISIT_CONFIRMATION);
                    if (visitForm.visitType === 'standard' && visitForm.startAvailability) {
                        visitConfirmationInstance.timing.startConditions = [
                            getStartAvailabilityCondition({
                                visit: initialVisit,
                                currentTimelineId,
                                startAvailabilityOption: visitForm.startAvailability,
                                prevConditionId: visitConfirmationInstance.timing.startConditions[0]?.id,
                            }),
                        ];
                    }
                    else {
                        // if this first visit is changing from standard, the new first visit needs to inherit the availability condition
                        if (standardVisits.length >= 2 && visitForm.startAvailability) {
                            const nextVisit = standardVisits[1];
                            const nextVisitConfirmationInstance = nextVisit.getActivitySequenceByActivityId(DefaultActivityId.VISIT_CONFIRMATION);
                            nextVisitConfirmationInstance.timing.startConditions = [
                                getStartAvailabilityCondition({
                                    visit: nextVisit,
                                    currentTimelineId,
                                    startAvailabilityOption: visitForm.startAvailability,
                                    prevConditionId: nextVisitConfirmationInstance.timing.startConditions[0]?.id,
                                }),
                            ];
                        }
                        visitConfirmationInstance.timing.startConditions = [];
                    }
                }
                const visitActivityOrderChanged = initialVisit.sequentialActivityOrder !== visitForm.sequentialActivityOrder;
                initialVisit.name = visitForm.name;
                initialVisit.details = getNewEncounterDetails(visitForm, initialVisit);
                initialVisit.activitySequence = initialVisit.activitySequence.map((sequence) => ({
                    ...sequence,
                    timing: {
                        ...sequence.timing,
                        name: visitForm.name,
                    },
                }));
                currentStudy.participantStatusConditions = generateParticipantStatusConditions(initialVisit.id);
                if (initialVisit.visitType === 'unscheduled' && initialVisit.code?.endsWith('01')) {
                    initialVisit.code = initialVisit.code.slice(0, -2);
                }
                if (visitActivityOrderChanged && visitForm.sequentialActivityOrder) {
                    initialVisit.resetParticipantActivityStartConditions();
                }
                onSave(initialVisit);
                logEvent('study_visit_edited', { study_id: studyId, visit_id: visitForm.name });
            }
        }
    };
    const createVisit = (scheduleTimelineId) => {
        return new SDKVisit({
            name: visitForm.name,
            details: getNewEncounterDetails(visitForm),
            scheduleTimelineId,
        });
    };
    const saveButton = {
        label: isCloning ? translate('Clone') : initialVisit ? translate('Save') : translate('Create'),
        onClick: handleSave,
        disabled: !isSaveEnabled,
    };
    const cancelButton = {
        label: translate('Cancel'),
        onClick: onCancel,
    };
    const handleMaxOccurrencesBlur = () => {
        if (visitForm.maxOccurrences > 50) {
            updateVisitForm({ maxOccurrences: 50 });
        }
        else if (visitForm.maxOccurrences < 1) {
            updateVisitForm({ maxOccurrences: 1 });
        }
    };
    const handleMaxOccurrencesChange = (event) => {
        const { value } = event.target;
        if (!value ||
            (ONLY_POSITIVE_INTEGERS_REGEX.test(value) && Number(value) <= MAX_RECURRENCE_COUNT && Number(value) > 0)) {
            handleInputChange(event);
        }
    };
    const formSections = [
        {
            title: translate('Visit details'),
            content: (_jsxs("div", { className: 'flex flex-col gap-4 max-w-input-lg', children: [_jsx(Input, { dataTestId: 'create-visit-name-input', errorMessage: translate('A visit with this name already exists. Please choose a different name.'), hasError: hasSameName, id: 'name', label: translate('Visit name'), onChange: handleInputChange, ref: nameInput, required: true, value: visitForm.name, containLabels: true, width: 'full' }), !initialVisit?.isOnboarding && (_jsxs(_Fragment, { children: [_jsxs(RadioGroup, { dataTestId: 'create-visit-type-radio-group', onChange: handleVisitTypeChange, value: visitForm.visitType, children: [_jsx(RadioGroupOption, { option: {
                                            value: 'standard',
                                            label: translate('Scheduled'),
                                            description: translate('A visit that is a part of the planned schedule of events throughout the study'),
                                        }, disabled: initialVisit?.visitType === 'end_of_study' && followUpVisitExists }), _jsx(RadioGroupOption, { option: {
                                            value: 'end_of_study',
                                            label: translate('Early withdrawal'),
                                            description: translate('Occurs when the participant needs to withdraw from the study early. All active activities not in this visit will be disabled at time of early withdraw confirmation. If a follow-up visit exists, you will not be able to edit the early withdrawal visit type'),
                                        }, disabled: initialVisit?.visitType !== 'end_of_study' && earlyWithdrawalExists }), _jsx(RadioGroupOption, { option: {
                                            value: 'follow_up',
                                            label: translate('Early withdrawal follow up'),
                                            description: translate('Occurs after a participant withdraws from a study to monitor their health and safety, collect remaining data, and formally close out the participant. If you need a follow-up visit after the last study visit we recommend utilizing a scheduled visit type'),
                                        }, disabled: !earlyWithdrawalExists ||
                                            initialVisit?.visitType === 'end_of_study' ||
                                            (initialVisit?.visitType !== 'follow_up' && followUpVisitExists) }), _jsx(RadioGroupOption, { option: {
                                            value: 'unscheduled',
                                            label: translate('Unscheduled'),
                                            description: translate('Used to generate activities outside of the planned schedule as needed. Adding this visit type once will make it always available to site users.  Once one is complete, the next will become available'),
                                        }, disabled: (initialVisit?.visitType === 'end_of_study' && followUpVisitExists) ||
                                            (initialVisit?.visitType !== 'unscheduled' && unscheduledVisitExists) })] }), _jsx(Input, { dataTestId: 'create-visit-max-occurrences-input', id: 'maxOccurrences', label: translate('Number of occurrences (max 50)'), onChange: handleMaxOccurrencesChange, onBlur: handleMaxOccurrencesBlur, value: visitForm.maxOccurrences, disabled: visitForm.visitType !== 'unscheduled', type: 'number', min: 1, max: 50, width: 'full' })] })), _jsx(Input, { dataTestId: 'create-visit-code-input', description: translate('The visit code will associate activity responses to the visit and is displayed in data transfers.'), id: 'code', label: translate('Visit code'), onChange: handleInputChange, value: visitForm.code, containLabels: true, width: 'full', required: true }), isFirstVisit && visitForm.visitType === 'standard' && (_jsx(Select, { label: translate('Visit start availability'), description: translate('Select an onboarding activity that will make this visit available to begin'), dataTestId: 'create-visit-start-availability', onChange: (option) => handleSelectChange('startAvailability', option), options: startAvailabilityOptions, value: visitForm.startAvailability, width: 'full' })), !initialVisit?.isOnboarding && (_jsx(Checkbox, { dataTestId: 'create-visit-skippable-checkbox', checked: visitForm.isOptional, onChange: handleIsOptionalChange, label: translate('Allow visit to be skipped') })), _jsx(Checkbox, { dataTestId: 'create-visit-enable-televisit-checkbox', checked: visitForm.isTelevisitEnabled, onChange: handleIsTelevisitEnabled, label: translate('Enable televisit') })] })),
        },
        {
            title: translate('Activity order'),
            description: translate('Configure how visit activities are made available to participants.'),
            content: (_jsx("section", { className: 'max-w-input-lg', children: _jsxs(RadioGroup, { dataTestId: 'create-visit-activity-order-radio-group', onChange: handleActivityOrderChange, value: visitForm.sequentialActivityOrder, children: [_jsx(RadioGroupOption, { option: {
                                value: false,
                                label: translate('Concurrent'),
                                description: translate('Participant activities will become available all at the same time'),
                            } }), _jsx(RadioGroupOption, { option: {
                                value: true,
                                label: translate('Sequential'),
                                description: translate('All participant activities in this visit start after the visit confirmation and must be completed in the order that they are listed in the schedule'),
                            } })] }) })),
        },
    ];
    if (!initialVisit || currentTimelineId !== DefaultTimelineId.ONBOARDING) {
        formSections.push({
            title: translate('Status assignment'),
            description: translate('Assign a status to participants at the start of this visit.'),
            content: (_jsx("section", { className: 'max-w-input-lg', children: _jsx(Select, { dataTestId: 'create-visit-status-option', onChange: (option) => handleSelectChange('status', option), options: statusOptions, value: visitForm.status, width: 'full' }) })),
        });
    }
    if (!initialVisit?.isOnboarding) {
        formSections.push({
            title: translate('Window'),
            description: translate('Select when this visit should become available in your schedule timeline relative to the first visit'),
            content: (_jsxs("section", { className: 'max-w-input-lg', children: [_jsx(Input, { dataTestId: 'create-visit-days-from-start-input', disabled: visits.length === 0, id: 'daysFromStart', label: translate('Days from start'), min: '0', onChange: handleDaysFromStartChange, type: 'number', value: visitForm.daysFromStart, width: 'full', containLabels: true }), _jsx(Text, { size: 'sm', className: 'font-medium pt-4', children: translate('Offset (optional)') }), _jsxs("div", { className: 'flex gap-4', children: [_jsx(Select, { dataTestId: 'create-visit-minus-select', description: translate('Days before'), onChange: (option) => handleSelectChange('minus', option), options: dayOptions, value: visitForm.minus, width: 'full' }), _jsx(Select, { dataTestId: 'create-visit-plus-select', description: translate('Days after'), onChange: (option) => handleSelectChange('plus', option), options: dayOptions, value: visitForm.plus, width: 'full' })] })] })),
        });
    }
    return (_jsx(ModalWindow, { title: isCloning ? translate('Clone visit') : initialVisit ? translate('Edit visit') : translate('Create visit'), subtitle: !isCloning && initialVisit ? visitForm.name : null, isOpen: true, closeWindow: onCancel, footerPrimaryActionButton: saveButton, footerSecondaryActionButtons: [cancelButton], width: 'full', children: _jsx("form", { className: 'flex min-h-[16rem] flex-col', onSubmit: onSubmit, autoComplete: 'off', onKeyUp: handleEnterPress, children: _jsx(FormSections, { sections: formSections }) }) }));
};
