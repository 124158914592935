import { stepResponseTypes } from '../constants/step_response_type.constants';
import { EStepTypes } from '../constants/step_type.enum';
export const getStepResponseType = ({ c_type, c_date_only }) => {
    switch (c_type) {
        case EStepTypes.COMPLETION:
            return stepResponseTypes.cCompletion.objectType;
        case EStepTypes.DATETIME:
            return c_date_only ? stepResponseTypes.cDate.objectType : stepResponseTypes.cDatetime.objectType;
        case EStepTypes.NUMERIC:
            return stepResponseTypes.cNumeric.objectType;
        case EStepTypes.TEXT:
            return stepResponseTypes.cText.objectType;
        case EStepTypes.BOOLEAN:
            return stepResponseTypes.cBoolean.objectType;
        case EStepTypes.TEXT_CHOICE:
            return stepResponseTypes.cTextChoice.objectType;
        case EStepTypes.REVIEW:
            return stepResponseTypes.cReview.objectType;
        case EStepTypes.FORM:
            return stepResponseTypes.cForm.objectType;
        case EStepTypes.PARTICIPANT_ID:
            return stepResponseTypes.cParticipantId.objectType;
        default:
            return '';
    }
};
