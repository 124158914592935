import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Pill } from 'libs.nucleus.pill';
export const StudyTestPlanExecutionStatus = ({ testPlanExecutionHistory, }) => {
    const translate = useLocalizeMessage();
    const statusMap = useMemo(() => {
        return {
            RUNNING: { iconColor: 'yellow', label: translate('Running') },
            PASSED: { iconColor: 'green', label: translate('Passed') },
            FAILED: { iconColor: 'red', label: translate('Failed') },
            ERROR: { iconColor: 'red', label: translate('Error') },
        };
    }, []);
    return useMemo(() => {
        const deploymentStatus = testPlanExecutionHistory.status;
        const { label, iconColor } = statusMap[deploymentStatus] || statusMap.ERROR;
        return _jsx(Pill, { label: label, iconBackgroundColor: iconColor, variant: 'status' });
    }, [translate, statusMap]);
};
